import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./authSlice";
// import coffeeReducer from "./coffeeSlice";
import registrationSlice from "./registrationSlice";
import requestSlice from "./slices/requestSlice";
import { stripeApi } from "../services/stripeApi"; // API actual
import userSlice from "./userSlice";
import { stripeOneTimeApi } from "../services/stripeOneTimeApi";

const store = configureStore({
  reducer: {
    auth: authReducer,
    user: userSlice,
    // coffee: coffeeReducer,
    registration: registrationSlice,
    request: requestSlice,
    [stripeApi.reducerPath]: stripeApi.reducer,
    [stripeOneTimeApi.reducerPath]: stripeOneTimeApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(stripeApi.middleware, stripeOneTimeApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
