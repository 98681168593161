import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Heading,
  Flex,
  Image,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Input,
  Button,
  useToast,
  Skeleton,
  Spinner,
  Fade,
  useMediaQuery,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Grid,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Divider,
  Tooltip
} from '@chakra-ui/react';
import { useParams, useNavigate } from 'react-router-dom';
import axiosInstance from '../api/axiosConfig';
import { Bar, Line, Radar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  RadarController,
  RadialLinearScale,
  Title,
  Tooltip as ChartTooltip,
  Legend,
} from 'chart.js';
import { FaChevronLeft, FaSortUp, FaSortDown, FaTshirt } from 'react-icons/fa';
import { FlagIcon, FlagIconCode } from 'react-flag-kit';
import Select from 'react-select';
import { LuTimerReset } from 'react-icons/lu';
import { GrRun } from 'react-icons/gr';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  RadarController,
  RadialLinearScale,
  Title,
  ChartTooltip,
  Legend
);

// ---------------- Interfaces ----------------
interface PlayerInfo {
  firstName: string;
  initial?: string;
  lastName: string;
  maidenName?: string;
  uniformNumber: string;
  battingHand: string;
  throwingHand: string;
  position: string;
  age: number;
  profileImage: string;
  statsCode: string;
  countryCode: string;
  graduationYear: string;
  aimingColleges: string;
  dateOfBirth: string;
  city: string;
  state: string;
  country: string;
  school: string;
  weight: string;
  height: string;
}

interface BattingStats {
  AB: number;
  R: number;
  '1B': number;
  '2B': number;
  '3B': number;
  HR: number;
  RBI: number;
  BB: number;
  AVG: number;
  OBP: number;
  SLG: number;
  OPS: number;
  [key: string]: any;
}

interface PitchingStats {
  G: number;
  W: number;
  L: number;
  SV: number;
  IP: number;
  ERA: number;
  K: number;
  BB: number;
  [key: string]: any;
}

interface FieldingStats {
  G: number;
  ET: number;
  EF: number;
  ERR: number;
  PO: number;
  A: number;
  SBA: number;
  CS: number;
  DP: number;
  PB: number;
  [key: string]: any;
}

interface Measures {
  team: string;
  yards: string;
  popTime: string;
  sixtyFeet: string;
  velocity: {
    IF: string;
    OF: string;
    C: string;
  };
  showcaseName?: string;
}

interface Ranking {
  yards: string;
  popTime: string;
  sixtyFeet: string;
  velocityIF: string;
  velocityOF: string;
  velocityC: string;
}

interface SyntheticStats {
  pitcherStats: { inning: string; mph: string[] }[];
}

// ---------------- Componente auxiliar: DynamicImage ----------------
interface DynamicImageProps {
  basePath: string;
  extensions: string[];
  alt: string;
  w: string;
  h: string;
  [x: string]: any;
}

const formatNumber3 = (value: any): string => {
  const num = parseFloat(value);
  if (isNaN(num)) return value;
  let formatted = num.toFixed(3);
  if (formatted.startsWith("0") && num < 1) {
    formatted = formatted.substring(1);
  }
  return formatted;
};

const formatNumber2 = (value: any): string => {
  const num = parseFloat(value);
  if (isNaN(num)) return value;
  let formatted = num.toFixed(2);
  if (formatted.startsWith("0") && num < 1) {
    formatted = formatted.substring(1);
  }
  return formatted;
};

const DynamicImage: React.FC<DynamicImageProps> = ({ basePath, extensions, alt, w, h, ...rest }) => {
  const [currentExtIndex, setCurrentExtIndex] = useState(0);
  const src = `${basePath}.${extensions[currentExtIndex]}`;
  return (
    <Image
      src={src}
      alt={alt}
      w={w}
      h={h}
      objectFit="cover"
      onError={() => {
        if (currentExtIndex < extensions.length - 1) {
          setCurrentExtIndex(currentExtIndex + 1);
        }
      }}
      {...rest}
    />
  );
};

// ---------------- Opciones y estilos para react-select ----------------
const customSelectStyles = {
  control: (provided: any) => ({
    ...provided,
    background: 'white',
    borderColor: 'gray.500',
    '&:hover': { borderColor: 'gray.500' },
    cursor: 'pointer',
    minWidth: '150px'
  }),
  menu: (provided: any) => ({
    ...provided,
    background: 'white',
    borderColor: 'gray.100',
  }),
  option: (provided: any, state: { isFocused: boolean }) => ({
    ...provided,
    background: state.isFocused ? '#2C5282' : 'white',
    color: state.isFocused ? 'white' : '#2C5282',
    cursor: 'pointer',
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: '#2C5282',
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: '#2C5282',
  }),
  menuPortal: (base: any) => ({ ...base, zIndex: 9999 })
};

// ---------------- VISTA PREMIUM SIMULADA ----------------
const isPremiumView = false;

// ---------------- Listas de columnas para la tabla ----------------
const battingFreeCols = ['AB', 'R', '1B', '2B', '3B', 'HR', 'RBI', 'AVG', 'BB', 'OBP', 'SLG', 'OPS'];
const battingPremiumCols = ['PA', 'H', 'B', 'TB', 'OB', 'RC', 'BBI', 'KC', 'KS', 'SO', 'BB_K', 'BB_PA', 'HBP', 'SB', 'CS', 'PK', 'SCB', 'SF', 'SAC', 'RPA', 'OBPE', 'GPA', 'BABIP', '1_RPA', 'CT_PERCENT', 'CT2_PERCENT', 'LOBI', 'LOB', 'ROE', 'FC', 'CI', 'GDP', 'GTP', 'PA_RSP', 'AB_RSP', 'BB_RSP', 'HBP_RSP', 'SAC_RSP', 'CI_RSP', 'H_RSP', 'BA_RSP', 'GBS', 'GBM', 'GBH', 'LDS', 'LDM', 'LDH', 'PUS', 'PUM', 'PUH', 'FBS', 'FBM', 'FBH', 'GB_PERCENT', 'LD_PERCENT', 'FB_PERCENT', 'ZC', 'QAB1_PERCENT', 'QAB2_PERCENT', 'QAB3_PERCENT'];

const pitchingFreeCols = ['G', 'W', 'L', 'SV', 'IP', 'ERA', 'K', 'BB'];
const pitchingPremiumCols = ['BF', 'BALL', 'STR', 'PIT', 'R', 'RA', 'ER', 'ERA9', 'FIP', 'KC', 'KS', 'H', 'IBB', 'K_BB', 'K_GI', 'BB_GI', 'H_GI', 'HB', 'BK', 'WP', 'HR', 'WHIP', 'OBP', 'BAA', 'BABIP', 'GO', 'AO', 'FPS', 'FPB', 'FPS_PERCENT'];

const fieldingFreeCols = ['G', 'ET', 'EF', 'ERR', 'PO', 'A', 'SBA', 'CS', 'DP', 'PB'];
const fieldingPremiumCols = ['FP', 'TP', 'PKF', 'PK', 'CREATED_AT', 'UPDATED_AT', 'IS_HOME', 'UNIFORM_NUM'];

// ---------------- Configuración de sort para la tabla ----------------
interface SortConfig {
  key: 'GAME_DATE' | 'TEAM_NAME' | 'OPP';
  direction: 'asc' | 'desc';
}

// ---------------- Componente Principal ----------------
const PlayerFullStatsPage: React.FC = () => {
  const { statsCode } = useParams<{ statsCode: string }>();
  const navigate = useNavigate();
  const toast = useToast();
  const [isMobile] = useMediaQuery("(max-width: 960px)");

  // Estados generales del jugador (versión base)
  const [playerInfo, setPlayerInfo] = useState<PlayerInfo | null>(null);
  const [battingStats, setBattingStats] = useState<BattingStats | null>(null);
  const [pitchingStats, setPitchingStats] = useState<PitchingStats | null>(null);
  const [fieldingStats, setFieldingStats] = useState<FieldingStats | null>(null);
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [measures, setMeasures] = useState<Measures[] | null>(null);
  const [ranking, setRanking] = useState<Ranking | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  // Estado para seleccionar el showcase en el dropdown
  const [selectedShowcaseIndex, setSelectedShowcaseIndex] = useState<number>(0);

  // Estados para la pestaña Table
  const [statsType, setStatsType] = useState<'Batting' | 'Pitching' | 'Fielding'>('Batting');
  const [availableStatsOptions, setAvailableStatsOptions] = useState<Array<'Batting' | 'Pitching' | 'Fielding'>>([]);
  const [filteredStats, setFilteredStats] = useState<any[] | null>(null);
  const [filterDateFrom, setFilterDateFrom] = useState<string>("");
  const [filterDateTo, setFilterDateTo] = useState<string>("");
  const [filterTeam, setFilterTeam] = useState<string>("");
  const [teamOptions, setTeamOptions] = useState<{ value: string; label: string }[]>([]);

  // Estados para PVP
  const [pvpCode, setPvpCode] = useState<string>("");
  const [pvpStatsType, setPvpStatsType] = useState<'Batting' | 'Pitching' | 'Fielding'>('Batting');
  const [pvpStats, setPvpStats] = useState<any>(null);
  const [pvpPlayerInfo, setPvpPlayerInfo] = useState<PlayerInfo | null>(null);

  // Estados para Media
  const [selectedMediaImage, setSelectedMediaImage] = useState<string | null>(null);
  const [validImages, setValidImages] = useState<string[]>([]);

  // Estados para la pestaña Graphics (filtros y datos propios)
  const [graphStatsType, setGraphStatsType] = useState<'Batting' | 'Pitching' | 'Fielding'>('Batting');
  const [graphFilterDateFrom, setGraphFilterDateFrom] = useState<string>("");
  const [graphFilterDateTo, setGraphFilterDateTo] = useState<string>("");
  const [graphFilterTeam, setGraphFilterTeam] = useState<string>("");
  const [graphicFilteredStats, setGraphicFilteredStats] = useState<any[] | null>(null);
  const [graphLoading, setGraphLoading] = useState<boolean>(false);

  // Estado de sort para la tabla (por defecto: Date desc, Team y OPP asc)
  const [sortConfig, setSortConfig] = useState<SortConfig>({ key: 'GAME_DATE', direction: 'desc' });

  const calculateAge = (dateOfBirth: string) => {
    const birthDate = new Date(dateOfBirth);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  // ---------------- Funciones de Datos ----------------
  const fetchPlayerData = async () => {
    if (!statsCode) {
      setError("No se proporcionó un código de estadísticas.");
      setLoading(false);
      return;
    }
    try {
      const resPlayer = await axiosInstance.get(`/players/by-id/${statsCode}`);
      if (resPlayer.data.success) {
        const data = resPlayer.data.player;
        const info: PlayerInfo = {
          firstName: data.FIRST_NAME,
          initial: data.INITIAL || '',
          lastName: data.LAST_NAME,
          maidenName: data.MAIDEN_NAME || '',
          uniformNumber: data.UNIFORM_NUMBER,
          position: data.PLAYING_POSITIONS
            ? data.PLAYING_POSITIONS.split(', ').map((p: string) => p.split(' - ')[0]).join(', ')
            : 'N/A',
          age: data.DATE_OF_BIRTH ? calculateAge(data.DATE_OF_BIRTH) : 0,
          profileImage: `/images/VictorEvent/${data.STATS_PLAYER_CODE} F.jpg`,
          statsCode: data.STATS_PLAYER_CODE || statsCode || '',
          countryCode: data.STATE || 'PR',
          graduationYear: data.GRADUATION_YEAR || 'N/A',
          aimingColleges: data.AIMING_COLLEGES || 'N/A',
          battingHand: data.BATTING_HAND || 'N/A',
          throwingHand: data.THROWING_HAND || 'N/A',
          dateOfBirth: data.DATE_OF_BIRTH || '',
          city: data.CITY || 'N/A',
          state: data.STATE || 'N/A',
          country: data.COUNTRY || 'N/A',
          school: data.CURRENT_SCHOOL || 'N/A',
          weight: data.WEIGHT || 'N/A',
          height: data.HEIGHT || 'N/A'
        };
        setPlayerInfo(info);

        // Estadísticas base
        try {
          const resBatting = await axiosInstance.get(`/players/by-id/${info.statsCode}/batting`);
          setBattingStats(resBatting.data.success ? resBatting.data.battingStats : null);
        } catch (err) {
          console.error("Error fetching batting stats:", err);
          setBattingStats(null);
        }
        try {
          const resPitching = await axiosInstance.get(`/players/by-id/${info.statsCode}/pitching`);
          setPitchingStats(resPitching.data.success ? resPitching.data.pitchingStats : null);
        } catch (err) {
          console.error("Error fetching pitching stats:", err);
          setPitchingStats(null);
        }
        try {
          const resFielding = await axiosInstance.get(`/players/by-id/${info.statsCode}/fielding`);
          setFieldingStats(resFielding.data.success ? resFielding.data.fieldingStats : null);
        } catch (err) {
          console.error("Error fetching fielding stats:", err);
          setFieldingStats(null);
        }
        try {
          const resMeasures = await axiosInstance.get(`/players/by-id/${info.statsCode}/measuresall`);
          setMeasures(resMeasures.data.success ? resMeasures.data.measures : null);
        } catch (err) {
          console.error("Error fetching measures:", err);
          setMeasures(null);
        }
      } else {
        throw new Error("Jugador no encontrado.");
      }
    } catch (err: any) {
      setError(err.message || "Error al obtener datos del jugador.");
      toast({
        title: "Error",
        description: err.message || "No se pudieron obtener los datos del jugador.",
        status: "error",
        duration: 10000,
        isClosable: true,
      });
      navigate('/');
    }
    setLoading(false);
  };

  // Cuando se cambia el tipo de estadística en Table se resetean los datos y se recargan
  useEffect(() => {
    setFilteredStats(null);
    fetchFilteredStats();
  }, [statsType]);

  // Efecto para cargar datos para Graphics (filtros propios)
  useEffect(() => {
    setGraphicFilteredStats(null);
    fetchGraphicStats();
  }, [graphStatsType, graphFilterDateFrom, graphFilterDateTo, graphFilterTeam, statsCode]);

  // Actualiza las opciones disponibles para el dropdown según las estadísticas existentes
  useEffect(() => {
    const available: Array<'Batting' | 'Fielding' | 'Pitching'> = [];
    if (battingStats) available.push('Batting');
    if (fieldingStats) available.push('Fielding');
    if (pitchingStats) available.push('Pitching');
    if (available.length > 0) {
      const defaultType = (['Batting', 'Fielding', 'Pitching'] as const).find(type => available.includes(type));
      setStatsType(defaultType!);
    }
    setAvailableStatsOptions(available);
  }, [battingStats, fieldingStats, pitchingStats]);

  const availableOptionsForSelect = availableStatsOptions.map(option => ({ value: option, label: option }));

  // Función para obtener datos filtrados para Table
  const fetchFilteredStats = async () => {
    if (!statsCode) return;
    if (filterDateFrom && filterDateTo && new Date(filterDateTo) < new Date(filterDateFrom)) {
      toast({
        title: "Rango de fechas inválido",
        description: "La End Date no puede ser anterior a la Start Date.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    setTableLoading(true);
    try {
      let endpoint = '';
      if (statsType === 'Batting') {
        endpoint = `/players/by-id/${statsCode}/batting/filtered`;
      } else if (statsType === 'Pitching') {
        endpoint = `/players/by-id/${statsCode}/pitching/filtered`;
      } else if (statsType === 'Fielding') {
        endpoint = `/players/by-id/${statsCode}/fielding/filtered`;
      }
      const params: any = {};
      if (filterDateFrom) params.dateFrom = filterDateFrom;
      if (filterDateTo) params.dateTo = filterDateTo;
      if (filterTeam) params.team = filterTeam;
      const res = await axiosInstance.get(endpoint, { params });
      if (res.data.success) {
        const dataArray: any[] = res.data[statsType.toLowerCase() + 'Stats'] || [];
        const processed = dataArray.map(row => {
          if (!row.OPP && row.TEAM_NAME && row.HOME_TEAM_NAME && row.VISIT_TEAM_NAME) {
            row.OPP = row.TEAM_NAME === row.HOME_TEAM_NAME ? row.VISIT_TEAM_NAME : row.HOME_TEAM_NAME;
          } else if (!row.OPP) {
            row.OPP = '';
          }
          return row;
        });
        setFilteredStats(processed);
      } else {
        setFilteredStats([]);
      }
    } catch (error: any) {
      console.error("Error fetching filtered stats:", error.message);
      toast({
        title: "Error",
        description: "Error fetching filtered stats.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setFilteredStats([]);
    }
    setTableLoading(false);
  };

  // Función para obtener datos filtrados para Graphics (filtros propios)
  const fetchGraphicStats = async () => {
    if (!statsCode) return;
    setGraphLoading(true);
    try {
      let endpoint = '';
      if (graphStatsType === 'Batting') {
        endpoint = `/players/by-id/${statsCode}/batting/filtered`;
      } else if (graphStatsType === 'Pitching') {
        endpoint = `/players/by-id/${statsCode}/pitching/filtered`;
      } else if (graphStatsType === 'Fielding') {
        endpoint = `/players/by-id/${statsCode}/fielding/filtered`;
      }
      const params: any = {};
      if (graphFilterDateFrom) params.dateFrom = graphFilterDateFrom;
      if (graphFilterDateTo) params.dateTo = graphFilterDateTo;
      if (graphFilterTeam) params.team = graphFilterTeam;
      const res = await axiosInstance.get(endpoint, { params });
      if (res.data.success) {
        const dataArray: any[] = res.data[graphStatsType.toLowerCase() + 'Stats'] || [];
        const processed = dataArray.map(row => {
          if (!row.OPP && row.TEAM_NAME && row.HOME_TEAM_NAME && row.VISIT_TEAM_NAME) {
            row.OPP = row.TEAM_NAME === row.HOME_TEAM_NAME ? row.VISIT_TEAM_NAME : row.HOME_TEAM_NAME;
          } else if (!row.OPP) {
            row.OPP = '';
          }
          return row;
        });
        setGraphicFilteredStats(processed);
      } else {
        setGraphicFilteredStats([]);
      }
    } catch (error: any) {
      console.error("Error fetching graphic stats:", error.message);
      setGraphicFilteredStats([]);
    }
    setGraphLoading(false);
  };

  const fetchFilteredTeams = async () => {
    if (!statsCode) return;
    try {
      const params: any = {};
      if (filterDateFrom) params.dateFrom = filterDateFrom;
      if (filterDateTo) params.dateTo = filterDateTo;
      const res = await axiosInstance.get(`/players/by-id/${statsCode}/teams/filtered`, { params });
      if (res.data.success) {
        const teams = res.data.teams;
        const opts = teams.map((team: string) => ({ value: team, label: team }));
        setTeamOptions(opts);
      }
    } catch (error: any) {
      console.error("Error fetching filtered teams:", error.message);
    }
  };

  const fetchPvpData = async () => {
    if (!pvpCode) return;
    try {
      let endpoint = `/players/by-id/${pvpCode}/batting`;
      if (pvpStatsType === 'Pitching') endpoint = `/players/by-id/${pvpCode}/pitching`;
      else if (pvpStatsType === 'Fielding') endpoint = `/players/by-id/${pvpCode}/fielding`;
      const resPvp = await axiosInstance.get(endpoint);
      if (resPvp.data.success) {
        if (pvpStatsType === 'Batting') setPvpStats(resPvp.data.battingStats);
        else if (pvpStatsType === 'Pitching') setPvpStats(resPvp.data.pitchingStats);
        else if (pvpStatsType === 'Fielding') setPvpStats(resPvp.data.fieldingStats);
      }
      const resInfo = await axiosInstance.get(`/players/by-id/${pvpCode}`);
      if (resInfo.data.success) {
        const data = resInfo.data.player;
        const info: PlayerInfo = {
          firstName: data.FIRST_NAME,
          initial: data.INITIAL || '',
          lastName: data.LAST_NAME,
          maidenName: data.MAIDEN_NAME || '',
          uniformNumber: data.UNIFORM_NUMBER,
          position: data.PLAYING_POSITIONS
            ? data.PLAYING_POSITIONS.split(', ').map((p: string) => p.split(' - ')[0]).join(', ')
            : 'N/A',
          age: data.DATE_OF_BIRTH ? calculateAge(data.DATE_OF_BIRTH) : 0,
          profileImage: `/images/VictorEvent/${data.STATS_PLAYER_CODE} F.jpg`,
          statsCode: data.STATS_PLAYER_CODE || pvpCode || '',
          countryCode: data.STATE || 'PR',
          graduationYear: data.GRADUATION_YEAR || 'N/A',
          battingHand: data.BATTING_HAND || 'N/A',
          throwingHand: data.THROWING_HAND || 'N/A',
          aimingColleges: data.AIMING_COLLEGES || 'N/A',
          dateOfBirth: data.DATE_OF_BIRTH || '',
          city: data.CITY || 'N/A',
          state: data.STATE || 'N/A',
          country: data.COUNTRY || 'N/A',
          school: data.CURRENT_SCHOOL || 'N/A',
          weight: data.WEIGHT || 'N/A',
          height: data.HEIGHT || 'N/A',
        };
        setPvpPlayerInfo(info);
      }
    } catch (err) {
      toast({
        title: "Error",
        description: "Error fetching PVP data.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // ---------------- Función para agrupar datos filtrados ----------------
  const aggregateFilteredStats = (data: any[]) => {
    return data.reduce((acc, row) => {
      Object.keys(row).forEach(key => {
        if (typeof row[key] === 'number') {
          acc[key] = (acc[key] || 0) + row[key];
        }
      });
      return acc;
    }, {} as any);
  };

  // ---------------- Ordenamiento de la Tabla ----------------
  const sortedStats = React.useMemo(() => {
    if (!filteredStats || !sortConfig) return filteredStats;
    return [...filteredStats].sort((a, b) => {
      let aValue = a[sortConfig.key];
      let bValue = b[sortConfig.key];
      if (sortConfig.key === 'GAME_DATE') {
        aValue = new Date(aValue);
        bValue = new Date(bValue);
      } else {
        aValue = aValue ? aValue.toString().toLowerCase() : '';
        bValue = bValue ? bValue.toString().toLowerCase() : '';
      }
      if (aValue < bValue) return sortConfig.direction === 'asc' ? -1 : 1;
      if (aValue > bValue) return sortConfig.direction === 'asc' ? 1 : -1;
      return 0;
    });
  }, [filteredStats, sortConfig]);

  const onSort = (columnKey: 'GAME_DATE' | 'TEAM_NAME' | 'OPP') => {
    if (sortConfig && sortConfig.key === columnKey) {
      setSortConfig({ key: columnKey, direction: sortConfig.direction === 'asc' ? 'desc' : 'asc' });
    } else {
      setSortConfig({ key: columnKey, direction: 'asc' });
    }
  };

  const renderSortIcon = (columnKey: 'GAME_DATE' | 'TEAM_NAME' | 'OPP') => {
    if (sortConfig && sortConfig.key === columnKey) {
      return sortConfig.direction === 'asc'
        ? <FaSortUp style={{ fontSize: '1.2em', marginLeft: '4px' }} />
        : <FaSortDown style={{ fontSize: '1.2em', marginLeft: '4px' }} />;
    } else {
      return columnKey === 'GAME_DATE'
        ? <FaSortDown style={{ fontSize: '1.2em', marginLeft: '4px' }} />
        : <FaSortUp style={{ fontSize: '1.2em', marginLeft: '4px' }} />;
    }
  };

  // ---------------- Render Functions ----------------
  const renderPersonalInfo = () => {
    if (!playerInfo) return null;
    return (
      <Box bg="white" p={8} borderRadius="xl" boxShadow="lg" mb={6} border="1px solid #D0D0E1">
        <Grid templateColumns={{ base: '1fr', md: '16% 35% 49%' }} gap={6}>
          <Box>
            <Image
              src={`/images/VictorEvent/${playerInfo.statsCode} F.jpg`}
              alt={`${playerInfo.firstName} ${playerInfo.lastName}`}
              boxSize={isMobile ? "150px" : "180px"}
              borderRadius="10px"
              objectFit="cover"
              border="2px solid #1B154B"
              bg="white"
              onError={(e) => {
                const img = e.currentTarget as HTMLImageElement;
                if (img.src.endsWith('.jpg')) {
                  img.src = img.src.replace('.jpg', '.JPG');
                } else {
                  img.src = '/images/player_user6.jpg';
                }
              }}
            />
          </Box>
          <Box fontSize="md" color="gray.700">
            <Heading as="h2" size="lg" color="#1B154B" mb={6}>
              {playerInfo.firstName} {playerInfo.initial} {playerInfo.lastName} {playerInfo.maidenName}
            </Heading>
            <Text mb={2}><strong>Position:</strong> {playerInfo.position}</Text>
            <Text mb={2}>
              <strong>Born:</strong> {playerInfo.dateOfBirth ? new Date(playerInfo.dateOfBirth).toLocaleDateString() : ''} ({playerInfo.age} yrs.)
            </Text>
            <Text mb={2}><strong>Bats | Throws:</strong> {playerInfo.battingHand} | {playerInfo.throwingHand}</Text>
            <Text mb={2}>
              <strong>Weight | Height:</strong> {playerInfo.weight} lbs | {playerInfo.height}
            </Text>
          </Box>
          <Box fontSize="md" color="gray.700">
            <Flex align="center" mb={4}>
              <FlagIcon
                code={playerInfo.countryCode === 'PR' ? 'PR' : (playerInfo.countryCode as FlagIconCode)}
                size={45}
              />
              <Box ml={2} position="relative">
                <FaTshirt size={45} color="#1B154B" />
                <Text
                  position="absolute"
                  top="50%"
                  left="50%"
                  transform="translate(-50%, -50%)"
                  fontWeight="bold"
                  color="white"
                  fontSize="sm"
                >
                  {playerInfo.uniformNumber}
                </Text>
              </Box>
              <Box ml={4}>
                <Text fontSize="sm" fontWeight="bold" color="#1B154B">STATS Code</Text>
                <Text fontSize="md" fontWeight="bold" color="gray.700">{playerInfo.statsCode}</Text>
              </Box>
            </Flex>
            <Text mb={2}><strong>School:</strong> {playerInfo.school}</Text>
            <Text mb={2}><strong>Expected Grad Year:</strong> {playerInfo.graduationYear}</Text>
            <Text mb={2}><strong>Aiming Colleges:</strong> {playerInfo.aimingColleges}</Text>
            <Text mb={2}>
              <strong>Address:</strong> {playerInfo.city}, {playerInfo.state}, {playerInfo.country}
            </Text>
          </Box>
        </Grid>
      </Box>
    );
  };

  // Render de la sección Table con transición suave
  const renderStatsTable = () => {
    const dateColWidth = 100;
    const teamColWidth = 100;
    const oppColWidth = 100;

    // Si aún se están cargando datos o no se han obtenido, mostramos el Skeleton
    if (tableLoading || filteredStats === null) {
      let freeCols: string[] = [];
      let premiumCols: string[] = [];
      if (statsType === 'Batting') {
        freeCols = battingFreeCols;
        premiumCols = battingPremiumCols;
      } else if (statsType === 'Pitching') {
        freeCols = pitchingFreeCols;
        premiumCols = pitchingPremiumCols;
      } else if (statsType === 'Fielding') {
        freeCols = fieldingFreeCols;
        premiumCols = fieldingPremiumCols;
      }
      const allStatCols = [...freeCols, ...premiumCols];
      return (
        <Box overflowX="auto">
          <Table variant="simple">
            <Thead>
              <Tr bg="gray.100">
                <Th style={{ position: 'sticky', left: 0, background: 'white', zIndex: 4, width: dateColWidth }} whiteSpace="nowrap">
                  Date
                </Th>
                <Th style={{ position: 'sticky', left: dateColWidth, background: 'white', zIndex: 4, width: teamColWidth }} whiteSpace="nowrap">
                  Team
                </Th>
                <Th style={{ position: 'sticky', left: dateColWidth + teamColWidth, background: 'white', zIndex: 4, width: oppColWidth }} whiteSpace="nowrap">
                  OPP
                </Th>
                {allStatCols.map((col) => (
                  <Th key={col} whiteSpace="nowrap">{col}</Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {[...Array(3)].map((_, i) => (
                <Tr key={i}>
                  <Td style={{ position: 'sticky', left: 0, background: 'white', zIndex: 1, width: dateColWidth }}>
                    <Skeleton height="20px" />
                  </Td>
                  <Td style={{ position: 'sticky', left: dateColWidth, background: 'white', zIndex: 1, width: teamColWidth }}>
                    <Skeleton height="20px" />
                  </Td>
                  <Td style={{ position: 'sticky', left: dateColWidth + teamColWidth, background: 'white', zIndex: 1, width: oppColWidth }}>
                    <Skeleton height="20px" />
                  </Td>
                  {allStatCols.map((col) => (
                    <Td key={col + i}>
                      <Skeleton height="20px" />
                    </Td>
                  ))}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      );
    }

    const dataToRender = sortedStats || [];
    if (dataToRender.length === 0) return <Text>No statistics available.</Text>;

    // En lugar de mostrar celdas con "N/A", se dejan vacías
    let freeCols: string[] = [];
    let premiumCols: string[] = [];
    if (statsType === 'Batting') {
      freeCols = battingFreeCols;
      premiumCols = battingPremiumCols;
    } else if (statsType === 'Pitching') {
      freeCols = pitchingFreeCols;
      premiumCols = pitchingPremiumCols;
    } else if (statsType === 'Fielding') {
      freeCols = fieldingFreeCols;
      premiumCols = fieldingPremiumCols;
    }
    const allStatCols = [...freeCols, ...premiumCols];

    return (
      <Fade in={!tableLoading} transition={{ enter: { duration: 0.5 } }}>
        <Box overflowX="auto">
          <Table variant="simple">
            <Thead>
              <Tr bg="gray.100">
                <Th
                  style={{ position: 'sticky', left: 0, background: 'white', zIndex: 4, width: dateColWidth }}
                  whiteSpace="nowrap"
                  onClick={() => onSort('GAME_DATE')}
                  cursor="pointer"
                >
                  Date {renderSortIcon('GAME_DATE')}
                </Th>
                <Th
                  style={{ position: 'sticky', left: dateColWidth, background: 'white', zIndex: 4, width: teamColWidth }}
                  whiteSpace="nowrap"
                  onClick={() => onSort('TEAM_NAME')}
                  cursor="pointer"
                >
                  Team {renderSortIcon('TEAM_NAME')}
                </Th>
                <Th
                  style={{ position: 'sticky', left: dateColWidth + teamColWidth, background: 'white', zIndex: 4, width: oppColWidth }}
                  whiteSpace="nowrap"
                  onClick={() => onSort('OPP')}
                  cursor="pointer"
                >
                  OPP {renderSortIcon('OPP')}
                </Th>
                {allStatCols.map((col) => (
                  <Th key={col} whiteSpace="nowrap">{col}</Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {dataToRender.map((row, index) => {
                let opp = '';
                if (row.TEAM_NAME && row.HOME_TEAM_NAME && row.VISIT_TEAM_NAME) {
                  opp = row.TEAM_NAME === row.HOME_TEAM_NAME ? row.VISIT_TEAM_NAME : row.HOME_TEAM_NAME;
                }
                return (
                  <Tr key={index}>
                    <Td style={{ position: 'sticky', left: 0, background: 'white', zIndex: 1, width: dateColWidth }} whiteSpace="nowrap">
                      {new Date(row.GAME_DATE).toLocaleDateString()}
                    </Td>
                    <Td style={{ position: 'sticky', left: dateColWidth, background: 'white', zIndex: 1, width: teamColWidth }} whiteSpace="nowrap">
                      {row.TEAM_NAME || ''}
                    </Td>
                    <Td style={{ position: 'sticky', left: dateColWidth + teamColWidth, background: 'white', zIndex: 1, width: oppColWidth }} whiteSpace="nowrap">
                      {opp}
                    </Td>
                    {allStatCols.map((col) => {
                      const value = row[col];
                      if (!isPremiumView && premiumCols.includes(col)) {
                        return (
                          <Td key={col} whiteSpace="nowrap">
                            <Text opacity={0.5}>ONLY PREMIUM USERS</Text>
                          </Td>
                        );
                      }
                      if (value !== undefined && value !== null && ['AVG', 'ABG', 'OBP', 'SLG', 'OPS'].includes(col)) {
                        return (
                          <Td key={col} whiteSpace="nowrap">
                            {formatNumber3(value)}
                          </Td>
                        );
                      }
                      if (value !== undefined && value !== null && ['IP', 'ERA'].includes(col)) {
                        return (
                          <Td key={col} whiteSpace="nowrap">
                            {formatNumber2(value)}
                          </Td>
                        );
                      }
                      return (
                        <Td key={col} whiteSpace="nowrap">
                          {value !== undefined && value !== null ? value : ''}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Box>
      </Fade>
    );
  };

  // Render de Graphics con spinner mientras se cargan sus datos
  const renderGraphicsContent = () => {
    if (graphLoading) {
      return (
        <Flex align="center" justify="center" height="300px">
          <Spinner size="xl" color="blue.500" />
        </Flex>
      );
    }
    if (!graphicFilteredStats || graphicFilteredStats.length === 0) {
      return <Text>No graphics data available.</Text>;
    }
    const aggregated = aggregateFilteredStats(graphicFilteredStats);
    if (graphStatsType === 'Batting') {
      const labels = ['AB', 'R', '1B', '2B', '3B', 'HR', 'RBI'];
      const dataValues = labels.map(label => Number(aggregated[label] || 0));
      const barData = {
        labels,
        datasets: [
          {
            label: 'Batting - Bar Chart',
            data: dataValues,
            backgroundColor: 'rgba(27, 21, 75, 0.9)'
          }
        ]
      };
      const lineData = {
        labels,
        datasets: [
          {
            label: 'Batting - Line Chart',
            data: dataValues,
            borderColor: 'rgba(27, 21, 75, 0.9)',
            backgroundColor: 'rgba(27, 21, 75, 0.9)',
            fill: true,
            tension: 0.9
          }
        ]
      };
      const radarData = {
        labels,
        datasets: [
          {
            label: 'Batting - Radar Chart',
            data: dataValues,
            backgroundColor: 'rgba(27, 21, 75, 0.9)',
            borderColor: 'rgba(27, 21, 75, 0.9)'
          }
        ]
      };
      return (
        <Fade in={!graphLoading} transition={{ enter: { duration: 0.5 } }}>
          <Box>
            <Grid templateColumns="1fr 1fr" gap={4}>
              <Box>
                <Heading size="sm" mb={1} color="#1B154B">Batting - Bar Chart</Heading>
                <Text fontSize="xs" mb={2}>This chart displays key batting metrics as bars.</Text>
                <Box height="300px">
                  <Bar data={barData} options={{ responsive: true, maintainAspectRatio: false, plugins: { legend: { position: 'top' }, title: { display: true, text: 'Batting Bar Chart' } } }} />
                </Box>
                <Box mt={4} height="300px">
                  <Heading size="sm" mb={1} color="#1B154B">Batting - Line Chart</Heading>
                  <Text fontSize="xs" mb={2}>This line chart shows the trend in batting performance.</Text>
                  <Box height="300px">
                    <Line data={lineData} options={{ responsive: true, maintainAspectRatio: false, plugins: { legend: { position: 'top' }, title: { display: true, text: 'Batting Line Chart' } } }} />
                  </Box>
                </Box>
              </Box>
              <Box>
                <Heading size="sm" mb={1} color="#1B154B">Batting - Radar Chart</Heading>
                <Text fontSize="xs" mb={2}>The radar chart offers an overall view of batting performance across all metrics.</Text>
                <Box height="640px">
                  <Radar data={radarData} options={{ responsive: true, maintainAspectRatio: false, plugins: { legend: { position: 'top' }, title: { display: true, text: 'Batting Radar Chart' } } }} />
                </Box>
              </Box>
            </Grid>
          </Box>
        </Fade>
      );
    } else if (graphStatsType === 'Pitching') {
      const labels = ['G', 'W', 'L', 'SV', 'IP', 'ERA', 'K', 'BB'];
      const dataValues = labels.map(label => Number(aggregated[label] || 0));
      const barData = {
        labels,
        datasets: [
          {
            label: 'Pitching - Bar Chart',
            data: dataValues,
            backgroundColor: 'rgba(27, 21, 75, 0.9)'
          }
        ]
      };
      const lineData = {
        labels,
        datasets: [
          {
            label: 'Pitching - Line Chart',
            data: dataValues,
            borderColor: 'rgba(27, 21, 75, 0.9)',
            backgroundColor: 'rgba(27, 21, 75, 0.9)',
            fill: true,
            tension: 0.9
          }
        ]
      };
      const radarData = {
        labels,
        datasets: [
          {
            label: 'Pitching - Radar Chart',
            data: dataValues,
            backgroundColor: 'rgba(153,102,255,0.2)',
            borderColor: 'rgba(27, 21, 75, 0.9)',
            pointBackgroundColor: 'rgba(27, 21, 75, 0.9)'
          }
        ]
      };
      return (
        <Fade in={!graphLoading} transition={{ enter: { duration: 0.5 } }}>
          <Box>
            <Grid templateColumns="1fr 1fr" gap={4}>
              <Box>
                <Heading size="sm" mb={1} color="#1B154B">Pitching - Bar Chart</Heading>
                <Text fontSize="xs" mb={2}>This bar chart displays key pitching metrics as bars.</Text>
                <Box height="300px">
                  <Bar data={barData} options={{ responsive: true, maintainAspectRatio: false, plugins: { legend: { position: 'top' }, title: { display: true, text: 'Pitching Bar Chart' } } }} />
                </Box>
                <Box mt={4} height="300px">
                  <Heading size="sm" mb={1} color="#1B154B">Pitching - Line Chart</Heading>
                  <Text fontSize="xs" mb={2}>This line chart shows the trend in pitching performance.</Text>
                  <Box height="300px">
                    <Line data={lineData} options={{ responsive: true, maintainAspectRatio: false, plugins: { legend: { position: 'top' }, title: { display: true, text: 'Pitching Line Chart' } } }} />
                  </Box>
                </Box>
              </Box>
              <Box>
                <Heading size="sm" mb={1} color="#1B154B">Pitching - Radar Chart</Heading>
                <Text fontSize="xs" mb={2}>The radar chart offers an overall view of pitching performance.</Text>
                <Box height="640px">
                  <Radar data={radarData} options={{ responsive: true, maintainAspectRatio: false, plugins: { legend: { position: 'top' }, title: { display: true, text: 'Pitching Radar Chart' } } }} />
                </Box>
              </Box>
            </Grid>
          </Box>
        </Fade>
      );
    } else if (graphStatsType === 'Fielding') {
      const labels = ['G', 'ET', 'EF', 'ERR', 'PO', 'A', 'SBA', 'CS', 'DP', 'PB'];
      const dataValues = labels.map(label => Number(aggregated[label] || 0));
      const barData = {
        labels,
        datasets: [
          {
            label: 'Fielding - Bar Chart',
            data: dataValues,
            backgroundColor: 'rgba(27, 21, 75, 0.9)'
          }
        ]
      };
      const lineData = {
        labels,
        datasets: [
          {
            label: 'Fielding - Line Chart',
            data: dataValues,
            borderColor: 'rgba(27, 21, 75, 0.9)',
            backgroundColor: 'rgba(27, 21, 75, 0.9)',
            fill: true,
            tension: 0.9
          }
        ]
      };
      const radarData = {
        labels,
        datasets: [
          {
            label: 'Fielding - Radar Chart',
            data: dataValues,
            backgroundColor: 'rgba(27, 21, 75, 0.9)',
            borderColor: 'rgba(27, 21, 75, 0.9)'
          }
        ]
      };
      return (
        <Fade in={!graphLoading} transition={{ enter: { duration: 0.5 } }}>
          <Box>
            <Grid templateColumns="1fr 1fr" gap={4}>
              <Box>
                <Heading size="sm" mb={1} color="#1B154B">Fielding - Bar Chart</Heading>
                <Text fontSize="xs" mb={2}>This bar chart compares key fielding metrics.</Text>
                <Box height="300px">
                  <Bar data={barData} options={{ responsive: true, maintainAspectRatio: false, plugins: { legend: { position: 'top' }, title: { display: true, text: 'Fielding Bar Chart' } } }} />
                </Box>
                <Box mt={4} height="300px">
                  <Heading size="sm" mb={1} color="#1B154B">Fielding - Line Chart</Heading>
                  <Text fontSize="xs" mb={2}>This line chart shows the trends in fielding performance.</Text>
                  <Box height="300px">
                    <Line data={lineData} options={{ responsive: true, maintainAspectRatio: false, plugins: { legend: { position: 'top' }, title: { display: true, text: 'Fielding Line Chart' } } }} />
                  </Box>
                </Box>
              </Box>
              <Box>
                <Heading size="sm" mb={1} color="#1B154B">Fielding - Radar Chart</Heading>
                <Text fontSize="xs" mb={2}>The radar chart provides an overall view of fielding performance.</Text>
                <Box height="640px">
                  <Radar data={radarData} options={{ responsive: true, maintainAspectRatio: false, plugins: { legend: { position: 'top' }, title: { display: true, text: 'Fielding Radar Chart' } } }} />
                </Box>
              </Box>
            </Grid>
          </Box>
        </Fade>
      );
    } else {
      return <Text>No graphics data available.</Text>;
    }
  };

  // Render de la sección Showcase
  const renderShowcaseContent = () => {
    if (!measures) return <Text>No showcase data available.</Text>;
    const showcasesArray = Array.isArray(measures) ? measures : [measures];
    const showcaseOptions = showcasesArray.map((showcase, index) => ({
      value: index,
      label: showcase.showcaseName ? showcase.showcaseName : showcase.team
    }));
    const selectedShowcase = showcasesArray[selectedShowcaseIndex];
    return (
      <Box>
        <Flex align="center" mb="20px">
          <Text fontSize="sm" mr={2}>Select Showcase:</Text>
          <Box width="250px">
            <Select
              options={showcaseOptions}
              value={showcaseOptions[selectedShowcaseIndex]}
              onChange={(selectedOption) =>
                setSelectedShowcaseIndex(selectedOption ? selectedOption.value : 0)
              }
              styles={customSelectStyles}
              menuPortalTarget={document.body}
            />
          </Box>
        </Flex>
        <Grid templateColumns={{ base: '1fr', md: '2fr 2fr' }} gap={4}>
          <Box bg="white" p={4} borderRadius="10px" border="1px solid #1B154B" boxShadow="sm">
            <Flex align="center" mb={3}>
              <LuTimerReset size={30} style={{ marginRight: "8px" }} />
              <Heading size="md" color="#1B154B">Time Metrics</Heading>
            </Flex>
            <Grid templateColumns="repeat(2, 1fr)" gap={2}>
              <Box bg="gray.50" p={3} borderRadius="10px" border="1px solid black">
                <Text fontSize="md" fontWeight="bold" mb="3px">60 Yards</Text>
                <Text fontSize="md" ml="4px">
                  {selectedShowcase.yards} <Text as="sup" fontSize="sm" fontWeight="normal" ml={1}>sec</Text>
                </Text>
              </Box>
              <Box bg="gray.50" p={3} borderRadius="10px" border="1px solid black">
                <Text fontSize="md" fontWeight="bold" mb="3px">Pop Time</Text>
                <Text fontSize="md" ml="4px">
                  {selectedShowcase.popTime} <Text as="sup" fontSize="sm" fontWeight="normal" ml={1}>sec</Text>
                </Text>
              </Box>
            </Grid>
          </Box>
          <Box bg="white" p={4} borderRadius="10px" border="1px solid #1B154B" boxShadow="sm">
            <Flex align="center" mb={3}>
              <GrRun size={30} style={{ marginRight: "8px" }} />
              <Heading size="md" color="#1B154B">Velocity Metrics</Heading>
            </Flex>
            <Grid templateColumns="repeat(2, 1fr)" gap={2}>
              <Box bg="gray.50" p={3} borderRadius="10px" border="1px solid black">
                <Text fontSize="md" fontWeight="bold" mb="3px">60ft Fastball</Text>
                <Text fontSize="md" ml="4px">
                  {selectedShowcase.sixtyFeet} <Text as="sup" fontSize="sm" fontWeight="normal" ml={1}>mph</Text>
                </Text>
              </Box>
              <Box bg="gray.50" p={3} borderRadius="10px" border="1px solid black">
                <Text fontSize="md" fontWeight="bold" mb="3px">IF Velocity</Text>
                <Text fontSize="md" ml="4px">
                  {selectedShowcase.velocity.IF} <Text as="sup" fontSize="sm" fontWeight="normal" ml={1}>mph</Text>
                </Text>
              </Box>
              <Box bg="gray.50" p={3} borderRadius="10px" border="1px solid black">
                <Text fontSize="md" fontWeight="bold" mb="3px">OF Velocity</Text>
                <Text fontSize="md" ml="4px">
                  {selectedShowcase.velocity.OF} <Text as="sup" fontSize="sm" fontWeight="normal" ml={1}>mph</Text>
                </Text>
              </Box>
              <Box bg="gray.50" p={3} borderRadius="10px" border="1px solid black">
                <Text fontSize="md" fontWeight="bold" mb="3px">C Velocity</Text>
                <Text fontSize="md" ml="4px">
                  {selectedShowcase.velocity.C} <Text as="sup" fontSize="sm" fontWeight="normal" ml={1}>mph</Text>
                </Text>
              </Box>
            </Grid>
          </Box>
        </Grid>
      </Box>
    );
  };

  const renderPvpContent = () => {
    let metrics: string[] = [];
    let mainStats: any = null;
    if (pvpStatsType === 'Batting') {
      metrics = ['AB','R','1B','2B','3B','HR','RBI','AVG','BB','OBP','SLG','OPS'];
      mainStats = battingStats;
    } else if (pvpStatsType === 'Pitching') {
      metrics = ['G','W','L','SV','IP','ERA','K','BB'];
      mainStats = pitchingStats;
    } else if (pvpStatsType === 'Fielding') {
      metrics = ['G','ET','EF','ERR','PO','A','SBA','CS','DP','PB'];
      mainStats = fieldingStats;
    }
    return (
      <Box>
        <Heading size="md" mb={4} color="#1B154B">Player Versus Player</Heading>
        <Flex mb={4} gap={4} align="center">
          <Input
            placeholder="Enter second player's Stats Code"
            value={pvpCode}
            onChange={(e) => setPvpCode(e.target.value)}
            bg="white"
          />
          <Box width="150px">
            <Select
              options={[
                { value: 'Batting', label: 'Batting' },
                { value: 'Pitching', label: 'Pitching' },
                { value: 'Fielding', label: 'Fielding' }
              ]}
              value={{ value: pvpStatsType, label: pvpStatsType }}
              onChange={(selectedOption) =>
                setPvpStatsType(selectedOption?.value as 'Batting' | 'Pitching' | 'Fielding')
              }
              styles={customSelectStyles}
              menuPortalTarget={document.body}
            />
          </Box>
          <Button colorScheme="blue" bg="#1B154B" onClick={async () => await fetchPvpData()}>
            Compare
          </Button>
        </Flex>
        {playerInfo && pvpPlayerInfo && (
          <Flex justify="space-between" align="center" mb={4}>
            <Flex align="center">
              <Image src={playerInfo.profileImage} objectFit="cover" boxSize="90px" borderRadius="10px" mr={2} />
              <Text fontWeight="bold" color="#1B154B">
                {playerInfo.firstName} {playerInfo.lastName}
              </Text>
            </Flex>
            <Text fontWeight="bold" color="#1B154B">VS</Text>
            <Flex align="center">
              <Text fontWeight="bold" color="#1B154B" mr={2}>
                {pvpPlayerInfo.firstName} {pvpPlayerInfo.lastName}
              </Text>
              <Image
                src={pvpPlayerInfo.profileImage ? pvpPlayerInfo.profileImage : '/images/player_user6.jpg'}
                objectFit="cover"
                boxSize="90px"
                borderRadius="10px"
                onError={(e) => {
                  const img = e.currentTarget as HTMLImageElement;
                  img.src = '/images/player_user6.jpg';
                }}
              />
            </Flex>
          </Flex>
        )}
        <Box>
          {pvpStats && mainStats ? (
            metrics.map((metric) => {
              const mainValue = Number((mainStats as any)[metric] || 0);
              const pvpValue = Number((pvpStats as any)[metric] || 0);
              const total = mainValue + pvpValue;
              const percentMain = total > 0 ? (mainValue / total) * 100 : 0;
              const percentPvp = total > 0 ? (pvpValue / total) * 100 : 0;
              return (
                <Flex key={metric} align="center" justify="center" mb={4}>
                  <Flex width="450px" justify="flex-start" align="center">
                    <Text ml={2} fontSize="sm">{mainValue.toFixed(3)}</Text>
                    <Box bg="blue.100" width={`${percentMain}%`} height="30px" borderRadius="md" ml={2} />
                  </Flex>
                  <Box width="100px" bg="gray.200" textAlign="center" p={1} borderRadius="md" mx={4}>
                    <Text fontWeight="bold">{metric}</Text>
                  </Box>
                  <Flex width="450px" justify="flex-end" align="center">
                    <Box bg="green.100" width={`${percentPvp}%`} height="30px" borderRadius="md" mr={2} />
                    <Text fontSize="sm">{pvpValue.toFixed(3)}</Text>
                  </Flex>
                </Flex>
              );
            })
          ) : (
            <Text textAlign="center">No PVP data available. Please enter a valid Stats Code.</Text>
          )}
        </Box>
      </Box>
    );
  };

  const renderMediaContent = () => {
    if (!playerInfo) return null;
    if (validImages.length === 0) return <Text>No media images available.</Text>;
    return (
      <>
        <Grid templateColumns={{ base: 'repeat(2, 1fr)', md: 'repeat(4, 1fr)' }} gap={4}>
          {validImages.map((url, index) => (
            <Box
              key={index}
              border="1px solid #D0D0E1"
              borderRadius="md"
              overflow="hidden"
              cursor="pointer"
              onClick={() => setSelectedMediaImage(url)}
            >
              <Image src={url} alt={`${playerInfo.statsCode} ${index}`} objectFit="cover" w="100%" h="250px" />
            </Box>
          ))}
        </Grid>
        {selectedMediaImage && (
          <Modal isOpen={true} onClose={() => setSelectedMediaImage(null)} isCentered>
            <ModalOverlay />
            <ModalContent w="auto" maxW="90vw" maxH="90vh" overflow="hidden">
              <ModalCloseButton bg="rgba(0, 0, 0, 0.5)" color="white" borderRadius="md" fontSize="lg" p={2} _hover={{ bg: "rgba(0, 0, 0, 0.7)" }} />
              <ModalBody p={0}>
                <Image src={selectedMediaImage} alt="Large view" maxW="100%" maxH="90vh" objectFit="contain" />
              </ModalBody>
            </ModalContent>
          </Modal>
        )}
      </>
    );
  };

  // ---------------- Efectos ----------------
  useEffect(() => {
    const fetchData = async () => {
      await fetchPlayerData();
    };
    fetchData();
  }, [statsCode, toast, navigate]);

  useEffect(() => {
    const fetchTableData = async () => {
      await fetchFilteredTeams();
      await fetchFilteredStats();
    };
    fetchTableData();
  }, [filterDateFrom, filterDateTo, filterTeam, statsType, statsCode, toast]);

  useEffect(() => {
    if (!playerInfo) return;
    const extensions = ['JPG', 'PNG', 'jpeg'];
    const patterns: string[] = [];
    const maxNumber = 5;
    for (let i = 1; i <= maxNumber; i++) {
      patterns.push(`${playerInfo.statsCode}-${i}`);
    }
    patterns.push(`${playerInfo.statsCode} F`);
    patterns.push(`${playerInfo.statsCode} B`);
    const checkImage = (url: string): Promise<string | null> => {
      return new Promise((resolve) => {
        const img = new window.Image();
        img.onload = () => resolve(url);
        img.onerror = () => resolve(null);
        img.src = url;
      });
    };
    const promises: Promise<string | null>[] = [];
    patterns.forEach((pattern) => {
      extensions.forEach((ext) => {
        const url = `/images/VictorEvent/${pattern}.${ext}`;
        promises.push(checkImage(url));
      });
    });
    Promise.all(promises).then((results) => {
      const valid = Array.from(new Set(results.filter((url) => url !== null) as string[]));
      if (valid.length === 0) {
        setValidImages(['/images/player_user6.jpg']);
      } else {
        setValidImages(valid);
      }
    });
  }, [playerInfo]);

  if (loading) {
    return (
      <Flex height="100vh" align="center" justify="center">
        <Skeleton height="200px" width="300px" />
      </Flex>
    );
  }

  if (error) {
    return (
      <Flex height="100vh" align="center" justify="center">
        <Text color="red.500" fontSize="xl">{error}</Text>
      </Flex>
    );
  }

  return (
    <Box width="100%" maxWidth="1125px" mx="auto" p={2} minHeight="100vh">
      <Flex gap={2} alignItems="center" mb={{ base: 3, md: 7 }}>
        <Button background="#fff" onClick={() => navigate('/my-roster')} width="40px">
          <FaChevronLeft fontSize="1.5em" />
        </Button>
        <Heading as="h3" fontSize={{ base: '18px', md: '24px', lg: '30px', xl: '3xl' }}>
          Player Ecosystem
        </Heading>
      </Flex>
      {renderPersonalInfo()}
      <Box bg="white" p={4} borderRadius="md" boxShadow="md" mb={6}>
        <Tabs variant="enclosed" colorScheme="blue">
          <TabList mb="1em">
            <Tab _selected={{ color: "white", bg: "#1B154B" }}>Table</Tab>
            <Tab _selected={{ color: "white", bg: "#1B154B" }}>Graphics</Tab>
            <Tab _selected={{ color: "white", bg: "#1B154B" }}>Showcase</Tab>
            <Tab _selected={{ color: "white", bg: "#1B154B" }}>PVP</Tab>
            <Tab _selected={{ color: "white", bg: "#1B154B" }}>Media</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Flex mb={4} align="center" gap={4}>
                <Flex align="center">
                  <Text fontSize="sm" mr={2}>Select Stat Type:</Text>
                  <Box width="160px">
                    <Select
                      options={availableOptionsForSelect}
                      value={{ value: statsType, label: statsType }}
                      onChange={(selectedOption) =>
                        setStatsType(selectedOption?.value as 'Batting' | 'Pitching' | 'Fielding')
                      }
                      styles={customSelectStyles}
                      menuPortalTarget={document.body}
                    />
                  </Box>
                </Flex>
                <Flex align="center">
                  <Text fontSize="sm" mr={2}>Team:</Text>
                  <Box width="200px">
                    <Select
                      options={teamOptions}
                      value={filterTeam ? { value: filterTeam, label: filterTeam } : null}
                      onChange={(selectedOption) => setFilterTeam(selectedOption ? selectedOption.value : "")}
                      styles={customSelectStyles}
                      isClearable
                      menuPortalTarget={document.body}
                    />
                  </Box>
                </Flex>
                <Flex align="center">
                  <Text fontSize="sm" mr={2}>Start Date:</Text>
                  <Input
                    type="date"
                    value={filterDateFrom}
                    onChange={(e) => setFilterDateFrom(e.target.value)}
                    max={filterDateTo || undefined}
                  />
                </Flex>
                <Flex align="center">
                  <Text fontSize="sm" mr={2}>End Date:</Text>
                  <Input
                    type="date"
                    value={filterDateTo}
                    onChange={(e) => setFilterDateTo(e.target.value)}
                    min={filterDateFrom || undefined}
                  />
                </Flex>
              </Flex>
              {renderStatsTable()}
            </TabPanel>
            <TabPanel>
              <Box mb={4}>
                <Flex mb={4} align="center" gap={4}>
                  <Flex align="center">
                    <Text fontSize="sm" mr={2}>Select Stat Type:</Text>
                    <Box width="160px">
                      <Select
                        options={availableOptionsForSelect}
                        value={{ value: graphStatsType, label: graphStatsType }}
                        onChange={(selectedOption) =>
                          setGraphStatsType(selectedOption?.value as 'Batting' | 'Pitching' | 'Fielding')
                        }
                        styles={customSelectStyles}
                        menuPortalTarget={document.body}
                      />
                    </Box>
                  </Flex>
                  <Flex align="center">
                    <Text fontSize="sm" mr={2}>Start Date:</Text>
                    <Input
                      type="date"
                      value={graphFilterDateFrom}
                      onChange={(e) => setGraphFilterDateFrom(e.target.value)}
                      max={graphFilterDateTo || undefined}
                    />
                  </Flex>
                  <Flex align="center">
                    <Text fontSize="sm" mr={2}>End Date:</Text>
                    <Input
                      type="date"
                      value={graphFilterDateTo}
                      onChange={(e) => setGraphFilterDateTo(e.target.value)}
                      min={graphFilterDateFrom || undefined}
                    />
                  </Flex>
                  <Flex align="center">
                    <Text fontSize="sm" mr={2}>Team:</Text>
                    <Box width="200px">
                      <Select
                        options={teamOptions}
                        value={graphFilterTeam ? { value: graphFilterTeam, label: graphFilterTeam } : null}
                        onChange={(selectedOption) =>
                          setGraphFilterTeam(selectedOption ? selectedOption.value : "")
                        }
                        styles={customSelectStyles}
                        isClearable
                        menuPortalTarget={document.body}
                      />
                    </Box>
                  </Flex>
                </Flex>
                <Grid>{renderGraphicsContent()}</Grid>
              </Box>
            </TabPanel>
            <TabPanel>{renderShowcaseContent()}</TabPanel>
            <TabPanel>{renderPvpContent()}</TabPanel>
            <TabPanel>{renderMediaContent()}</TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
      <Box textAlign="center" fontSize="sm" color="gray.600" mt={4}>
        <Text mb={2}>
          If you find any error, please contact us via email at <strong>support@statsdeportes.com</strong>
        </Text>
        <Text>
          Data provided by <strong>STATS</strong>. This page displays publicly available statistics collected by STATS about players in various sports.
        </Text>
        <Text>
          For more information, visit <a href="https://www.statsdeportes.com" target="_blank" rel="noopener noreferrer" style={{ color: 'blue', textDecoration: 'underline' }}>STATSDeportes</a>.
        </Text>
      </Box>
    </Box>
  );
};

export default PlayerFullStatsPage;
