import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Heading,
  SimpleGrid,
  Flex,
  Input,
  Button,
  Text,
  Badge,
  useToast,
  Skeleton,
} from "@chakra-ui/react";
import axiosInstance from "../api/axiosConfig";
import { Event } from "../types/Event";
import PaymentMethodSelection from "../components/players_registry/PaymentMethodSelection";

const ShowcasePlayers: React.FC = () => {
  const [events, setEvents] = useState<Event[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedEvent, setSelectedEvent] = useState<Event | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toast = useToast();

  const fetchEvents = async () => {
    setIsLoading(true);
    try {
      const res = await axiosInstance.get("/events");
      if (res.data.success) {
        const fetchedEvents = res.data.events.map((evt: any) => ({
          id: evt.ID,
          name: evt.NAME,
          location: evt.LOCATION,
          startDate: new Date(evt.START_DATE),
          endDate: new Date(evt.END_DATE),
          ageGroup: evt.AGE_GROUP,
          image: evt.IMAGE,
        }));
        setEvents(fetchedEvents);
      } else {
        toast({
          title: "Error",
          description: "No se pudieron obtener los eventos.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error: any) {
      console.error("Error fetching events:", error.message);
      toast({
        title: "Error",
        description: error.message || "Error fetching events.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  const filteredEvents = events.filter(
    (evt) =>
      (evt.name || "").toLowerCase().includes(searchQuery) ||
      (evt.location || "").toLowerCase().includes(searchQuery)
  );

  const handleOpenModal = (event: Event) => {
    const today = new Date();
    if (today > event.endDate) {
      toast({
        title: "Evento finalizado",
        description: "No se pueden agregar jugadores a un evento finalizado.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    setSelectedEvent(event);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Box width="100%" minHeight="100vh" bg="#F3F3F3">
      <Container maxW="1300px" py={10}>
        <Heading as="h1" size="xl" mb={6}>
          Events
        </Heading>
        <Flex mb={6} justifyContent="space-between" alignItems="center">
          <Input
            bg="white"
            placeholder="Search events"
            maxW="400px"
            value={searchQuery}
            onChange={handleSearch}
          />
        </Flex>
        {isLoading ? (
          <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
            {[1, 2, 3].map((n) => (
              <Skeleton key={n} height="300px" borderRadius="16px" />
            ))}
          </SimpleGrid>
        ) : (
          <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
            {filteredEvents.map((event) => {
              const today = new Date();
              let status = "Done";
              let badgeColor = "blue";
              if (today >= event.startDate && today <= event.endDate) {
                status = "Started";
                badgeColor = "green";
              } else if (today < event.startDate) {
                status = "Arrived";
                badgeColor = "purple";
              }
              const isEventFinished = today > event.endDate;
              const imageStr = event.image ? event.image.trim() : "";
              const bgImage = imageStr.startsWith("data:")
                ? imageStr
                : `/images/event_fondos/${imageStr || "Event1.jpg"}`;
              return (
                <Box
                  key={event.id}
                  position="relative"
                  borderRadius="16px"
                  overflow="hidden"
                  height="300px"
                  boxShadow="0px 0px 18.5px 1px rgba(0, 0, 0, 0.08)"
                  style={{
                    backgroundImage: `url("${bgImage}")`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    filter: isEventFinished ? "grayscale(100%)" : "none",
                  }}
                >
                  <Flex position="absolute" top={4} left={4}>
                    <Badge colorScheme={badgeColor} px={2} py={1} borderRadius="5px">
                      {status}
                    </Badge>
                  </Flex>
                  {!isEventFinished && (
                    <Flex position="absolute" top={4} right={4}>
                      <Button size="sm" colorScheme="blue" onClick={() => handleOpenModal(event)}>
                        Add Players
                      </Button>
                    </Flex>
                  )}
                  <Flex
                    position="absolute"
                    bottom={0}
                    width="100%"
                    bg="rgba(0,0,0,0.5)"
                    p={3}
                    justifyContent="center"
                    flexDir="column"
                    alignItems="center"
                  >
                    <Text color="white" fontWeight="bold" fontSize="lg">
                      {event.name}
                    </Text>
                    <Text color="white" fontSize="md">
                      {event.location}
                    </Text>
                    <Text color="white" fontSize="sm">
                      {`${event.startDate.toLocaleDateString()} - ${event.endDate.toLocaleDateString()}`}
                    </Text>
                  </Flex>
                </Box>
              );
            })}
          </SimpleGrid>
        )}
      </Container>
      {selectedEvent && (
        <PaymentMethodSelection
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          event={selectedEvent}
        />
      )}
    </Box>
  );
};

export default ShowcasePlayers;
