// src/components/EventFormModal.tsx (Modificación para Drag-and-Drop con cambios en colores, dropdown, divider y fuentes)
import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Grid,
  GridItem,
  HStack,
  Image,
  Box,
  Text,
  Icon,
  Divider
} from "@chakra-ui/react";
import { useDropzone } from "react-dropzone";
import { FiImage } from "react-icons/fi";
import WizardButton from "../Shared/WizardButton";
import ReactSelect from "react-select";
import { Event } from "../../types/Event";

// Definir estilos para react-select (paleta similar a PlayerFullStatsPage)
const customSelectStyles = {
  control: (provided: any) => ({
    ...provided,
    background: "white",
    borderColor: "gray.500",
    "&:hover": { borderColor: "gray.500" },
    cursor: "pointer",
    minWidth: "150px",
  }),
  menu: (provided: any) => ({
    ...provided,
    background: "white",
    borderColor: "gray.100",
  }),
  option: (provided: any, state: { isFocused: boolean }) => ({
    ...provided,
    background: state.isFocused ? "#2C5282" : "white",
    color: state.isFocused ? "white" : "#2C5282",
    cursor: "pointer",
    fontSize: "md",
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: "#2C5282",
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: "#2C5282",
  }),
  menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
};

const ageGroupOptions = [
  { value: "8-10 years", label: "8-10 years" },
  { value: "11-14 years", label: "11-14 years" },
  { value: "15-17 years", label: "15-17 years" },
];

interface EventFormModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (event: Event) => void;
  initialData?: Event;
}

const EventFormModal: React.FC<EventFormModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  initialData,
}) => {
  const [name, setName] = useState("");
  const [location, setLocation] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [ageGroup, setAgeGroup] = useState("");
  const [image, setImage] = useState<string | ArrayBuffer | null>(null);

  useEffect(() => {
    if (initialData) {
      setName(initialData.name);
      setLocation(initialData.location);
      setStartDate(initialData.startDate.toISOString().split("T")[0]);
      setEndDate(initialData.endDate.toISOString().split("T")[0]);
      setAgeGroup(initialData.ageGroup);
      setImage(initialData.image);
    } else {
      // Resetear los campos si no hay datos iniciales
      setName("");
      setLocation("");
      setStartDate("");
      setEndDate("");
      setAgeGroup("");
      setImage(null);
    }
  }, [initialData, isOpen]);

  const onDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles.length === 0) return;
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { "image/*": [] },
    multiple: false,
  });

  const handleSubmit = () => {
    if (!name || !location || !startDate || !endDate || !ageGroup || !image) {
      alert("Por favor, completa todos los campos.");
      return;
    }

    const newEvent: Event = {
      id: initialData ? initialData.id : Date.now().toString(),
      name,
      location,
      startDate: new Date(startDate),
      endDate: new Date(endDate),
      ageGroup,
      image: image as string,
    };

    onSubmit(newEvent);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent maxW="800px">
        <ModalHeader fontSize="2xl">
          {initialData ? "Edit Event" : "Add Events"}
        </ModalHeader>
        <Divider mb={4} />
        <ModalCloseButton />
        <ModalBody>
          <Grid templateColumns="repeat(2, minmax(300px, 1fr))" gap={4}>
            {/* Primera columna */}
            <GridItem>
              <FormControl id="name" isRequired>
                <FormLabel fontSize="lg">Event Name</FormLabel>
                <Input
                  fontSize="md"
                  placeholder="Enter event name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </FormControl>
              <FormControl id="location" isRequired mt={4}>
                <FormLabel fontSize="lg">Location</FormLabel>
                <Input
                  fontSize="md"
                  placeholder="Enter location"
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                />
              </FormControl>
            </GridItem>

            {/* Segunda columna */}
            <GridItem>
              <FormControl id="dateRange" isRequired>
                <FormLabel fontSize="lg">Event Date Range</FormLabel>
                <HStack>
                  <Input
                    fontSize="md"
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                  <Text fontSize="lg">to</Text>
                  <Input
                    fontSize="md"
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                </HStack>
              </FormControl>
              <FormControl id="ageGroup" isRequired mt={4}>
                <FormLabel fontSize="lg">Event Age Group</FormLabel>
                <ReactSelect
                  options={ageGroupOptions}
                  value={ageGroupOptions.find((opt) => opt.value === ageGroup)}
                  onChange={(selectedOption) =>
                    setAgeGroup(selectedOption ? selectedOption.value : "")
                  }
                  styles={customSelectStyles}
                  placeholder="Select age group"
                  menuPortalTarget={document.body}
                />
              </FormControl>
            </GridItem>

            {/* Campo de imagen en dos columnas */}
            <GridItem colSpan={2}>
              <FormControl id="image" isRequired>
                <FormLabel fontSize="lg">Event Image</FormLabel>
                <Box
                  {...getRootProps()}
                  border="2px dashed #D0D0E1"
                  borderRadius="6px"
                  p={4}
                  textAlign="center"
                  cursor="pointer"
                  position="relative"
                  overflow="hidden"
                  height="250px"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <input {...getInputProps()} />
                  {isDragActive ? (
                    <Text fontSize="lg">Drop the image here...</Text>
                  ) : image ? (
                    <Image
                      src={image as string}
                      alt="Event Image"
                      maxH="200px"
                      mx="auto"
                    />
                  ) : (
                    <>
                      <Icon as={FiImage} boxSize={10} color="gray.500" mb={2} />
                      <Text fontSize="lg">
                        Drag and drop an image, or click to select one
                      </Text>
                    </>
                  )}
                </Box>
              </FormControl>
            </GridItem>
          </Grid>
        </ModalBody>

        <ModalFooter>
          <WizardButton variant="submit" onClick={handleSubmit}>
            {initialData ? "Update" : "Create"}
          </WizardButton>
          <WizardButton variant="cancel" ml={3} onClick={onClose}>
            Cancel
          </WizardButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EventFormModal;
