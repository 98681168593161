// src/components/players_registry/BuySpotsModal.tsx
import React, { useEffect, useState, useCallback } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Box,
  Text,
  Flex,
  IconButton,
  Image,
  Divider,
  useBreakpointValue,
  useToast,
} from '@chakra-ui/react';
import { FaCaretUp, FaCaretDown, FaCheckCircle } from 'react-icons/fa';
import WizardButton from '../Shared/WizardButton';
import { loadStripe } from '@stripe/stripe-js';
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';
// Importamos el nuevo hook para compras únicas
import { useCreateOneTimeCheckoutSessionMutation } from '../../services/stripeOneTimeApi';
import axiosInstance from '../../api/axiosConfig';

interface BuySpotsModalProps {
  isOpen: boolean;
  onClose: () => void;
  maxSpots: number;
  onSpotsPurchased: (newAvailableSpots: number) => void;
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || "");

const BuySpotsModal: React.FC<BuySpotsModalProps> = ({ isOpen, onClose, maxSpots, onSpotsPurchased }) => {
  const toast = useToast();
  // Flujo en 3 pasos: 1: Selección, 2: Revisión, 3: Pago
  const [step, setStep] = useState(1);
  const [quantity, setQuantity] = useState(1);
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);
  // Usamos el hook para one-time checkout
  const [createOneTimeCheckoutSession] = useCreateOneTimeCheckoutSessionMutation();

  const storedGuardian = JSON.parse(localStorage.getItem('user') || '{}');
  const guardianEmail = storedGuardian?.email;

  useEffect(() => {
    if (isOpen) {
      setStep(1);
      setQuantity(1);
      setClientSecret(null);
      setIsConfirmOpen(false);
    }
  }, [isOpen]);

  const modalSize = useBreakpointValue({ base: "full", md: "xl" });
  const minQuantity = 1;

  const handleNext = () => {
    if (step === 1) {
      setStep(2);
    } else if (step === 2) {
      setStep(3);
    }
  };

  const handlePrevious = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const changeQuantity = (change: number) => {
    setQuantity((prev) => Math.max(minQuantity, Math.min(prev + change, maxSpots)));
  };

  const handleMouseDown = (change: number) => {
    changeQuantity(change);
    const id = setInterval(() => changeQuantity(change), 100);
    setIntervalId(id);
  };

  const handleMouseUp = () => {
    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(null);
    }
  };

  // Calcula el monto total en centavos (ej: spots a $6.99, tarifa premium $19.99, impuestos $1.99)
  const computeAmount = () => {
    const total = quantity * 6.99 + 19.99 + 1.99;
    return Math.round(total * 100);
  };

  const fetchClientSecret = useCallback(async () => {
    if (!guardianEmail) {
      toast({
        title: 'Error',
        description: 'Guardian email not found.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    try {
      const payload = {
        amount: computeAmount(),
        guardianEmail,
        spots: quantity,
      };
      const response = await createOneTimeCheckoutSession(payload).unwrap();
      if (response.clientSecret) {
        setClientSecret(response.clientSecret);
      } else {
        toast({
          title: 'Error',
          description: 'Could not initiate payment.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error: any) {
      console.error("Error fetching client secret:", error);
      toast({
        title: 'Error',
        description: error.message || 'An error occurred while initiating payment.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  }, [guardianEmail, quantity, createOneTimeCheckoutSession, toast]);

  useEffect(() => {
    if (step === 3 && !clientSecret) {
      fetchClientSecret();
    }
  }, [step, clientSecret, fetchClientSecret]);

  const paymentOptions = {
    fetchClientSecret: async () => clientSecret || "",
    onComplete: async () => {
      try {
        const response = await axiosInstance.post('/guardian/buy-spots', {
          guardianEmail,
          spotsToBuy: quantity,
        });
        if (response.data.success) {
          onSpotsPurchased(response.data.availableSpots);
          setIsConfirmOpen(true);
        } else {
          toast({
            title: 'Purchase Failed',
            description: response.data.message || 'Could not complete the purchase.',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
      } catch (error) {
        console.error('Error completing purchase:', error);
        toast({
          title: 'Failed to Purchase Spots',
          description: 'An error occurred while finalizing the purchase.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    },
  };

  const closeConfirmModal = () => {
    setIsConfirmOpen(false);
    onClose();
  };

  const stepTitles = ['Select Spots', 'Review Payment', 'Payment'];

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size={modalSize} isCentered scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent px={6} py={4}>
          <ModalHeader textAlign="center">
            <Text fontSize="xl" fontWeight="bold" color="#1B154B">
              {stepTitles[step - 1]}
            </Text>
            <Divider mt={2} />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {step === 1 && (
              <Box textAlign="center">
                {maxSpots < 1 && (
                  <Text color="red.500" mb={4}>
                    Your account has reached the limit of allowed spots.
                  </Text>
                )}
                <Image
                  src="/images/confirmation.png"
                  alt="Baseball Player Icon"
                  mt={4}
                  boxSize="60px"
                  mb={4}
                  mx="auto"
                />
                <Text fontSize="lg" mb={4}>
                  How many players do you want to add to your Roster?
                </Text>
                <Flex alignItems="center" justifyContent="center" gap={4}>
                  <IconButton
                    aria-label="Decrease quantity"
                    icon={<FaCaretDown size={24} />}
                    onMouseDown={() => handleMouseDown(-1)}
                    onMouseUp={handleMouseUp}
                    onMouseLeave={handleMouseUp}
                    size="sm"
                    variant="ghost"
                    isDisabled={quantity <= minQuantity || maxSpots < 1}
                  />
                  <Text fontSize="4xl" fontWeight="bold" color="#1B154B">
                    {quantity} / {maxSpots}
                  </Text>
                  <IconButton
                    aria-label="Increase quantity"
                    icon={<FaCaretUp size={24} />}
                    onMouseDown={() => handleMouseDown(1)}
                    onMouseUp={handleMouseUp}
                    onMouseLeave={handleMouseUp}
                    size="sm"
                    variant="ghost"
                    isDisabled={quantity >= maxSpots || maxSpots < 1}
                  />
                </Flex>
              </Box>
            )}
            {step === 2 && (
              <Box textAlign="center" py={4}>
                <Text fontSize="lg" mb={4} color="#1B154B" fontWeight="semibold">
                  Payment Details
                </Text>
                <Box borderWidth="1px" borderColor="gray.200" borderRadius="md" p={4} mb={4}>
                  <Flex justifyContent="space-between" mb={2}>
                    <Text>Price per Spot:</Text>
                    <Text>${6.99.toFixed(2)}</Text>
                  </Flex>
                  <Flex justifyContent="space-between" mb={2}>
                    <Text>Number of Spots:</Text>
                    <Text>{quantity}</Text>
                  </Flex>
                  <Flex justifyContent="space-between" mb={2}>
                    <Text>Subtotal:</Text>
                    <Text>${(quantity * 6.99).toFixed(2)}</Text>
                  </Flex>
                  <Flex justifyContent="space-between" mb={2}>
                    <Text>Premium Fee:</Text>
                    <Text>$19.99</Text>
                  </Flex>
                  <Flex justifyContent="space-between" mb={2}>
                    <Text>Taxes:</Text>
                    <Text>$1.99</Text>
                  </Flex>
                  <Divider my={2} />
                  <Flex justifyContent="space-between" fontWeight="bold">
                    <Text>Total:</Text>
                    <Text>${(quantity * 6.99 + 19.99 + 1.99).toFixed(2)}</Text>
                  </Flex>
                </Box>
                <Text fontSize="sm" color="gray.600" mb={4}>
                  Your payment will be processed securely via Stripe.
                </Text>
              </Box>
            )}
            {step === 3 && (
              <Box textAlign="center">
                <Text fontSize="lg" fontWeight="bold" mb={4} color="#1B154B">
                  Payment
                </Text>
                {clientSecret ? (
                  <EmbeddedCheckoutProvider stripe={stripePromise} options={paymentOptions}>
                    <EmbeddedCheckout />
                  </EmbeddedCheckoutProvider>
                ) : (
                  <Text>Loading payment details...</Text>
                )}
              </Box>
            )}
          </ModalBody>
          <ModalFooter justifyContent="center" gap={4}>
            {step > 1 && (
              <WizardButton
                width="150px"
                variant="back"
                onClick={handlePrevious}
                _hover={{ backgroundColor: '#E2E8F0' }}
              >
                Back
              </WizardButton>
            )}
            {step < 3 && (
              <WizardButton
                width="150px"
                variant="next"
                onClick={handleNext}
                isDisabled={maxSpots < 1}
                _hover={{ bg: '#1B154B', opacity: 0.8 }}
              >
                Next
              </WizardButton>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
      {/* Modal de confirmación */}
      <Modal isOpen={isConfirmOpen} onClose={closeConfirmModal} isCentered size="md">
        <ModalOverlay />
        <ModalContent p={6}>
          <ModalCloseButton />
          <ModalBody textAlign="center" mt={4}>
            <Box mb={4}>
              <FaCheckCircle color="#1B154B" size={50} />
            </Box>
            <Text fontSize="2xl" fontWeight="bold" mb={4} color="#1B154B">
              Payment Successful
            </Text>
            <Text fontSize="md" mb={4}>
              The payment has been successfully completed.
            </Text>
            <Text fontSize="md" fontWeight="medium">
              You have purchased {quantity} spot{quantity > 1 ? 's' : ''} for a total of $
              {(quantity * 6.99 + 19.99 + 1.99).toFixed(2)}
            </Text>
          </ModalBody>
          <ModalFooter justifyContent="center">
            <WizardButton variant="next" onClick={closeConfirmModal} width="150px">
              Ok
            </WizardButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default BuySpotsModal;
