// src/components/players_registry/ModernCardBack.tsx
import React from 'react';
import {
  Box,
  Text,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Grid,
  Flex,
  Divider,
} from '@chakra-ui/react';
import { FaCheckCircle, FaTimesCircle, FaTrophy } from 'react-icons/fa';

export interface BattingStats {
  GAME_DATE: string;
  TEAM_NAME: string;
  OPP: string;
  AB: number;
  R: number;
  "1B": number;
  "2B": number;
  "3B": number;
  HR: number;
  RBI: number;
  AVG: number;
}

export interface PitchingStats {
  GAME_DATE: string;
  TEAM_NAME: string;
  OPP: string;
  G: number;
  W: number;
  L: number;
  SV: number;
  IP: number;
  ERA: number;
  K: number;
  BB: number;
}

export interface FieldingStats {
  GAME_DATE: string;
  TEAM_NAME: string;
  OPP: string;
  G: number;
  ET: number;
  EF: number;
  ERR: number;
  PO: number;
  A: number;
  SBA: number;
  CS: number;
  DP: number;
  PB: number;
}

export interface Ranking {
  yards: string;
  popTime: string;
  sixtyFeet: string;
  velocityIF: string;
  velocityOF: string;
  velocityC: string;
  showcaseName?: string;
}

interface ModernCardBackProps {
  playerName: string;
  height: string;
  weight: string;
  bats: string;
  throws: string;
  dateOfBirth: string;
  graduationYear: string;
  playingPositions: string;
  leagueValidated?: string;
  selectedPlan?: string; // "insurance" o "noInsurance"
  last3Batting: BattingStats[];
  last3Pitching: PitchingStats[];
  last3Fielding: FieldingStats[];
  ranking?: Ranking;
}

const trophyForRank = (rank: string) => {
  if (rank === "1st")
    return <FaTrophy color="gold" style={{ marginLeft: '0.25rem' }} />;
  if (rank === "2nd")
    return <FaTrophy color="silver" style={{ marginLeft: '0.25rem' }} />;
  if (rank === "3rd")
    return <FaTrophy color="#cd7f32" style={{ marginLeft: '0.25rem' }} />;
  return null;
};

const ModernCardBack: React.FC<ModernCardBackProps> = ({
  playerName,
  height,
  weight,
  bats,
  throws,
  dateOfBirth,
  graduationYear,
  playingPositions,
  selectedPlan,
  leagueValidated,
  last3Batting,
  last3Pitching,
  last3Fielding,
  ranking,
}) => {
  // Ordena cada array por GAME_DATE descendente y toma solo los 5 juegos más recientes
  const sortAndSlice = <T extends { GAME_DATE: string }>(data: T[]) =>
    data
      .slice()
      .sort(
        (a, b) =>
          new Date(b.GAME_DATE).getTime() - new Date(a.GAME_DATE).getTime()
      )
      .slice(0, 5);
  const sortedBatting = sortAndSlice(last3Batting);

  // Columnas gratuitas para Batting
  const battingCols = [
    "GAME_DATE",
    "AB",
    "R",
    "1B",
    "2B",
    "3B",
    "HR",
    "RBI",
    "AVG",
  ];

  // Función para formatear la fecha en formato MM/DD/YY (año en 2 dígitos)
  const formatDateTwoDigitYear = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      month: 'short', // 'short' devuelve el nombre del mes con tres letras (Ej: "Mar")
      day: '2-digit', // '2-digit' devuelve el día con dos dígitos (Ej: "27")
    }).replace(' ', '-'); // Reemplaza el espacio predeterminado con un guion
  };

  const renderTable = (cols: string[], data: any[], title: string) => (
    <Box mb={1} ml={-1} mr="2px">
      <Table
        size="xs"
        variant="simple"
        border="1px solid #09264a"
        borderRadius="8px"
        width="102%"
        minWidth="102%" 
        maxWidth="102%"
        overflow="hidden"
      >
        <caption
          style={{
            textAlign: 'center',
            fontWeight: 'bold',
            padding: '2px',
            backgroundColor: "#09264a",
            color: 'white',
          }}
        >
          {title}
        </caption>
        <Thead bg="#09264a" borderTopRadius="8px">
          <Tr>
            {cols.map((col, idx) => (
              <Th
                key={idx}
                fontSize="10px"
                color="white"
                px={2}
                py={1}
                textAlign="center"
                borderBottom="1px solid white"
              >
                {col === "GAME_DATE" ? "Date" : col}
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody bg="#09264a">
          {data.map((row, rowIdx) => (
            <Tr
              key={rowIdx}
              style={rowIdx === data.length - 1 ? { borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px' } : {}}
            >
              {cols.map((col, colIdx) => (
                <Td key={colIdx} fontSize="8px" color="white" px={2} py={1} textAlign="center">
                  {col === "GAME_DATE"
                    ? formatDateTwoDigitYear(row[col])
                    : col === "AVG"
                    ? String(Number(row[col]).toFixed(3)).replace(/^0+/, '') // Corrige AVG
                    : row[col] !== undefined
                    ? row[col]
                    : "N/A"}
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );

  const renderRanking = () => {
    if (!ranking)
      return (
        <Text fontSize="xs" color="#757575">
          No ranking data available.
        </Text>
      );
  
    const leftColumn = [
      { metric: '60 Yards', value: ranking.yards },
      { metric: 'Pop Time', value: ranking.popTime },
      { metric: '60ft Fastball', value: ranking.sixtyFeet },
    ];
  
    const rightColumn = [
      { metric: 'IF Velocity', value: ranking.velocityIF },
      { metric: 'OF Velocity', value: ranking.velocityOF },
      { metric: 'C Velocity', value: ranking.velocityC },
    ];
  
    return (
      <Flex gap={8}>
        <Box flex="1" ml="8px">
          {leftColumn.map((item, idx) => (
            <Flex key={idx} justifyContent="space-between" alignItems="center" mb={1}>
              <Text fontSize="10px" color="#1B154B" fontWeight="bold">
                {item.metric}
              </Text>
              <Flex alignItems="center">
                <Text fontSize="10px" color="#1B154B" fontWeight="bold">
                  {item.value || '-'}
                </Text>
                <Box w="14px" h="14px" ml="0.25rem" display="flex" alignItems="center">
                  {item.value && trophyForRank(item.value)}
                </Box>
              </Flex>
            </Flex>
          ))}
        </Box>
        <Box flex="1" mr="7px">
          {rightColumn.map((item, idx) => (
            <Flex key={idx} justifyContent="space-between" alignItems="center" mb={1}>
              <Text fontSize="10px" color="#1B154B" fontWeight="bold">
                {item.metric}
              </Text>
              <Flex alignItems="center">
                <Text fontSize="10px" color="#1B154B" fontWeight="bold">
                  {item.value || '-'}
                </Text>
                <Box w="14px" h="14px" ml="0.25rem" display="flex" alignItems="center">
                  {item.value && trophyForRank(item.value)}
                </Box>
              </Flex>
            </Flex>
          ))}
        </Box>
      </Flex>
    );
  };

  return (
    <Box
      width="100%"
      height="100%"
      bg="#FFFFFF"
      borderRadius="20px"
      p={3}
      overflowY="auto"
      display="flex"
      flexDirection="column"
    >
      {/* Información esencial del jugador */}
      <Box mb={1}>
        <Grid templateColumns="repeat(3, 1fr)" gap={1}>
          <Box>
            <Text fontSize="xs" color="#1B154B" fontWeight="bold">
              Height
            </Text>
            <Text fontSize="xs" color="#757575">
              {height}
            </Text>
          </Box>
          <Box>
            <Text fontSize="xs" color="#1B154B" fontWeight="bold">
              Weight
            </Text>
            <Text fontSize="xs" color="#757575">
              {weight}
            </Text>
          </Box>
          <Box>
            <Text fontSize="xs" color="#1B154B" fontWeight="bold">
              Bats / Throws
            </Text>
            <Text fontSize="xs" color="#757575">
              {bats} / {throws}
            </Text>
          </Box>
        </Grid>
        <Grid templateColumns="repeat(3, 1fr)" gap={1} mt={1}>
          <Box>
            <Text fontSize="xs" color="#1B154B" fontWeight="bold">
              Birthdate
            </Text>
            <Text fontSize="xs" color="#757575">
              {dateOfBirth ? new Date(dateOfBirth).toLocaleDateString() : ''}
            </Text>
          </Box>
          <Box>
            <Text fontSize="xs" color="#1B154B" fontWeight="bold">
              Graduation
            </Text>
            <Text fontSize="xs" color="#757575">
              {graduationYear}
            </Text>
          </Box>
          <Box>
            <Text fontSize="xs" color="#1B154B" fontWeight="bold">
              Positions
            </Text>
            <Text fontSize="xs" color="#757575">
            {playingPositions
                .split(',')
                .map(pos => pos.split(' - ')[0].trim())
                .filter(pos => pos)
                .join(', ')}
            </Text>
          </Box>
        </Grid>
      </Box>

      <Divider borderColor="#D0D0E1" my={2} />

      {/* Contenedor para la tabla de Batting */}
      <Box>
        {renderTable(battingCols, sortedBatting, "Last 5 Games")}
      </Box>

      <Divider borderColor="#D0D0E1" my={2} />

      {/* Encabezado de ranking: muestra el nombre del evento si está disponible */}
      <Heading
        as="h6"
        size="xs"
        bg="#09264a"
        color="white"
        p={1}
        borderRadius="4px"
        width="100%"
        textAlign="left"
      >
        {ranking && ranking.showcaseName ? ranking.showcaseName : "Last Showcase Ranking"}
      </Heading>
      <Box mt={1} ml="3px">
        {renderRanking()}
      </Box>

      <Divider borderColor="#D0D0E1" my={1} mb="10px"/>

      {/* Si el jugador tiene plan de insurance, mostramos la imagen */}
      <Box display="flex" flexDirection="row" alignItems="center" gap={2} ml="3px">
        <Box position="relative">
          <img
            src="/images/Multinational_Logo_2022.png"
            alt="Insurance Plan"
            style={{
              height: '40px',
              filter: selectedPlan === "insurance" ? 'none' : 'grayscale(100%)'
            }}
          />
          <Box position="absolute" bottom="0" right="0">
            {selectedPlan === "insurance" ? (
              <FaCheckCircle color="green" size={16} />
            ) : (
              <FaTimesCircle color="red" size={16} />
            )}
          </Box>
        </Box>

        <Box position="relative">
          <img
            src="/images/1639012665-grid.png"
            alt="League Image"
            style={{
              height: '40px',
              filter: leagueValidated === "League" ? 'none' : 'grayscale(100%)'
            }}
          />
          <Box position="absolute" bottom="0" right="0">
            {leagueValidated === "League" ? (
              <FaCheckCircle color="green" size={16} />
            ) : (
              <FaTimesCircle color="red" size={16} />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ModernCardBack;
