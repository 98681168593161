import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Checkbox,
  CheckboxGroup,
  Stack,
  useToast,
} from "@chakra-ui/react";
import {
  Stepper,
  Step,
  StepIndicator,
  StepStatus,
  StepSeparator,
  StepTitle,
} from "@chakra-ui/react";
import WizardButton from "../Shared/WizardButton";
import { FaApple, FaGoogle } from "react-icons/fa";
import { Event } from "../../types/Event";

const COST_PER_PLAYER = 100;

interface PaymentMethodSelectionProps {
  isOpen: boolean;
  onClose: () => void;
  event: Event;
}

const PaymentMethodSelection: React.FC<PaymentMethodSelectionProps> = ({
  isOpen,
  onClose,
  event,
}) => {
  const [step, setStep] = useState(1);
  const [selectedPlayers, setSelectedPlayers] = useState<string[]>([]);
  const [paymentMethod, setPaymentMethod] = useState("");
  const toast = useToast();

  const stepTitles = ["Select Players", "Payment", "Review"];

  // Mapeo para mostrar etiquetas de cada jugador
  const playerLabels: { [key: string]: string } = {
    player1: "Player 1",
    player2: "Player 2",
    player3: "Player 3",
  };

  useEffect(() => {
    if (isOpen) {
      setStep(1);
      setSelectedPlayers([]);
      setPaymentMethod("");
    }
  }, [isOpen]);

  const subtotal = selectedPlayers.length * COST_PER_PLAYER;
  const tax = subtotal * 0.1;
  const total = subtotal + tax;

  const handleNext = () => {
    if (step === 1) {
      if (selectedPlayers.length === 0) {
        toast({
          title: "Selecciona al menos un jugador",
          status: "warning",
          duration: 2000,
          isClosable: true,
        });
        return;
      }
      setStep(2);
    } else if (step === 2) {
      if (!paymentMethod) {
        toast({
          title: "Selecciona un método de pago",
          status: "warning",
          duration: 2000,
          isClosable: true,
        });
        return;
      }
      setStep(3);
    }
  };

  const handleBack = () => {
    if (step > 1) setStep(step - 1);
  };

  const handleConfirm = () => {
    toast({
      title: "Players added",
      description: `Total: $${total.toFixed(2)}`,
      status: "success",
      duration: 3000,
      isClosable: true,
    });
    onClose();
    setStep(1);
    setSelectedPlayers([]);
    setPaymentMethod("");
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
        setStep(1);
        setSelectedPlayers([]);
        setPaymentMethod("");
      }}
      isCentered
    >
      <ModalOverlay />
      {/* Se elimina la altura fija para que el contenido se adapte y se permita el scroll */}
      <ModalContent width="580px" maxW="90%" maxH="90%" px={6}>
        <ModalHeader>
          <Flex justify="center" mb={4}>
            <Stepper index={step - 1} colorScheme="blue" width="100%">
              {stepTitles.map((title, index) => (
                <Step key={index}>
                  <StepIndicator>{index + 1}</StepIndicator>
                  <StepStatus
                    complete={<StepTitle>{title}</StepTitle>}
                    incomplete={<StepTitle>{title}</StepTitle>}
                    active={<StepTitle>{title}</StepTitle>}
                  />
                  {index !== stepTitles.length - 1 && <StepSeparator />}
                </Step>
              ))}
            </Stepper>
          </Flex>
          Add Players to {event.name}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody textAlign="center" fontSize="sm" pb={6} overflowY="auto">
          {step === 1 && (
            <Box>
              <Text fontSize="lg" mb={4}>
                Select Players
              </Text>
              <CheckboxGroup
                value={selectedPlayers}
                onChange={(values) =>
                  setSelectedPlayers(values as string[])
                }
              >
                <Stack spacing={2}>
                  <Checkbox value="player1">Player 1</Checkbox>
                  <Checkbox value="player2">Player 2</Checkbox>
                  <Checkbox value="player3">Player 3</Checkbox>
                  {/* Agrega más checkboxes si es necesario */}
                </Stack>
              </CheckboxGroup>
            </Box>
          )}
          {step === 2 && (
            <Box textAlign="center">
              <Text fontSize="lg" fontWeight="bold" mb={4}>
                Select Payment Method
              </Text>
              <WizardButton
                variant="next"
                _hover={{ bg: "#1B154B", opacity: 0.8 }}
                leftIcon={<FaApple fontSize="20px" />}
                onClick={() => setPaymentMethod("Apple Pay")}
                mb={3}
                width="90%"
                height="50px"
              >
                Apple Pay
              </WizardButton>
              <WizardButton
                variant="next"
                _hover={{ bg: "#1B154B", opacity: 0.8 }}
                leftIcon={<FaGoogle fontSize="20px" />}
                onClick={() => setPaymentMethod("Google Pay")}
                mb={3}
                width="90%"
                height="50px"
              >
                Google Pay
              </WizardButton>
            </Box>
          )}
          {step === 3 && (
            <Box p={4}>
              <Text fontSize="xl" fontWeight="bold" mb={4}>
                Review
              </Text>
              <Box mb={3}>
                {selectedPlayers.map((player) => (
                  <Flex key={player} justify="space-between" mb={2}>
                    <Text fontSize="md">
                      {playerLabels[player] || player}
                    </Text>
                    <Text fontSize="md">
                      ${COST_PER_PLAYER.toFixed(2)}
                    </Text>
                  </Flex>
                ))}
              </Box>
              <Flex justify="space-between" mt={4}>
                <Text fontWeight="bold">Subtotal</Text>
                <Text fontWeight="bold">
                  ${subtotal.toFixed(2)}
                </Text>
              </Flex>
              <Flex justify="space-between" mt={2}>
                <Text fontWeight="bold">Tax (10%)</Text>
                <Text fontWeight="bold">
                  ${tax.toFixed(2)}
                </Text>
              </Flex>
              <Flex justify="space-between" mt={2}>
                <Text fontSize="lg" fontWeight="bold">
                  Total
                </Text>
                <Text fontSize="lg" fontWeight="bold">
                  ${total.toFixed(2)}
                </Text>
              </Flex>
            </Box>
          )}
        </ModalBody>
        <ModalFooter justifyContent="center" gap={4}>
          {step === 1 && (
            <>
              <WizardButton variant="cancel" onClick={onClose}>
                Cancel
              </WizardButton>
              <WizardButton variant="next" onClick={handleNext}>
                Next
              </WizardButton>
            </>
          )}
          {step === 2 && (
            <>
              <WizardButton variant="back" onClick={handleBack}>
                Back
              </WizardButton>
              <WizardButton variant="next" onClick={handleNext}>
                Next
              </WizardButton>
            </>
          )}
          {step === 3 && (
            <>
              <WizardButton variant="back" onClick={handleBack}>
                Back
              </WizardButton>
              <WizardButton variant="submit" onClick={handleConfirm}>
                Confirm
              </WizardButton>
            </>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PaymentMethodSelection;
