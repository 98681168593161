import {
    Box,
    Container,
    Heading,
    SimpleGrid,
    Text,
    Image,
    Flex,
  } from "@chakra-ui/react";
  import { NavLink } from "react-router-dom";
  import { useEffect, useState } from "react";
  import { useDispatch } from "react-redux";
  import { AppDispatch } from "../store";
  import { mapUserAttributes } from "../utils/helpers";
  import { fetchUserInfo } from "../store/authSlice";
  
  const Association = () => {
    const dispatch = useDispatch<AppDispatch>();
    const [user, setUser] = useState<any>(null);
    const [role, setRole] = useState<string>("");
  
    useEffect(() => {
      const fetchAndStoreUser = async () => {
        const accessToken = localStorage.getItem("accessToken");
        const storedUser = localStorage.getItem("user");
  
        if (storedUser) {
          const userObject = JSON.parse(storedUser);
          setUser(userObject);
          setRole(userObject["custom:role"] || "");
        } else if (accessToken) {
          try {
            const result = await dispatch(fetchUserInfo({ accessToken })).unwrap();
            if (result?.user?.UserAttributes) {
              const userAttributes = result.user;
              const userObject = mapUserAttributes(userAttributes.UserAttributes);
              if (userObject) {
                localStorage.setItem("user", JSON.stringify(userObject));
                setUser(userObject);
                setRole(userObject["custom:role"] || "");
              } else {
                console.warn("El objeto de usuario es nulo o indefinido.");
              }
            }
          } catch (error) {
            console.error("Error al obtener la información del usuario:", error);
          }
        } else {
          console.warn("No hay token de acceso disponible.");
        }
      };
  
      fetchAndStoreUser();
    }, [dispatch]);
  
    return (
      <Box width="100%" maxWidth="1300px" height="100%">
        <Container margin={0} px={6}>
          <Heading as="h1" size="xl" mb={6}>
            Association
          </Heading>
          <SimpleGrid
            templateColumns={{
              base: "1fr",
              sm: "repeat(2, 1fr)",
              lg: "repeat(3, 1fr)",
            }}
            gap={{ base: 4, md: 6 }}
          >
            {/* Opción League */}
            {/* <NavLink to="/league">
              <Box
                _hover={{
                  boxShadow: "lg",
                  transition: "all .3s",
                }}
                transition={"all .3s"}
                cursor="pointer"
                bg="white"
                p={6}
                width="220px"
                borderRadius="20px"
                border="1px solid #D0D0E1"
                height="100%" // Asegura que todos los boxes tengan la misma altura
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
              >
                <Box>
                  <Text fontSize="lg" fontWeight="bold" mb={4}>
                    League
                  </Text>
                </Box>
                <Flex justifyContent="flex-end">
                  <Image
                    alt="Ilustración de una liga"
                    boxSize="60px"
                    src="/images/League.png"
                  />
                </Flex>
              </Box>
            </NavLink> */}
  
            {/* Opción Tournament */}
            {/* <NavLink to="/tournament">
              <Box
                _hover={{
                  boxShadow: "lg",
                  transition: "all .3s",
                }}
                transition={"all .3s"}
                cursor="pointer"
                bg="white"
                p={6}
                width="220px"
                borderRadius="20px"
                border="1px solid #D0D0E1"
                height="100%"
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
              >
                <Box>
                  <Text fontSize="lg" fontWeight="bold" mb={4}>
                    Tournament
                  </Text>
                </Box>
                <Flex justifyContent="flex-end">
                  <Image
                    alt="Ilustración de un torneo"
                    boxSize="60px"
                    src="/images/Tournament.png"
                  />
                </Flex>
              </Box>
            </NavLink> */}
  
            {/* Opción Event */}
            <NavLink to="/event">
              <Box
                _hover={{
                  boxShadow: "lg",
                  transition: "all .3s",
                }}
                transition={"all .3s"}
                cursor="pointer"
                bg="white"
                p={6}
                width="220px"
                borderRadius="20px"
                border="1px solid #D0D0E1"
                height="100%"
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
              >
                <Box>
                  <Text fontSize="lg" fontWeight="bold" mb={4}>
                    Event
                  </Text>
                </Box>
                <Flex justifyContent="flex-end">
                  <Image
                    alt="Ilustración de un evento"
                    boxSize="60px"
                    src="/images/event.png"
                  />
                </Flex>
              </Box>
            </NavLink>
          </SimpleGrid>
        </Container>
      </Box>
    );
  };
  
  export default Association;
  