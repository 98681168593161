// src/pages/Event.tsx
import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Heading,
  SimpleGrid,
  Flex,
  Input,
  Button,
  IconButton,
  Text,
  Badge,
  useToast,
  Skeleton,
} from "@chakra-ui/react";
import { FiPlus, FiEdit, FiTrash2 } from "react-icons/fi";
import axiosInstance from "../api/axiosConfig";
import EventFormModal from "../components/common/EventFormModal";
import { Event } from "../types/Event";

const EventPage: React.FC = () => {
  const [events, setEvents] = useState<Event[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingEvent, setEditingEvent] = useState<Event | null>(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const toast = useToast();

  // Obtener eventos del API y mapear campos a minúsculas
  const fetchEvents = async () => {
    setIsLoading(true);
    try {
      const res = await axiosInstance.get("/events");
      if (res.data.success) {
        const fetchedEvents = res.data.events.map((evt: any) => ({
          id: evt.ID,
          name: evt.NAME,
          location: evt.LOCATION,
          startDate: new Date(evt.START_DATE),
          endDate: new Date(evt.END_DATE),
          ageGroup: evt.AGE_GROUP,
          image: evt.IMAGE,
        }));
        setEvents(fetchedEvents);
      } else {
        toast({
          title: "Error",
          description: "No se pudieron obtener los eventos.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error: any) {
      console.error("Error fetching events:", error.message);
      toast({
        title: "Error",
        description: error.message || "Error fetching events.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  const handleAddEvent = () => {
    setEditingEvent(null);
    setIsModalOpen(true);
  };

  const handleEditEvent = (event: Event) => {
    setEditingEvent(event);
    setIsModalOpen(true);
  };

  const handleDeleteEvent = async (eventId: string) => {
    if (isDeleting) return; // Evitar doble eliminación
    setIsDeleting(true);
    try {
      const res = await axiosInstance.delete(`/events/${eventId}`);
      if (res.data.success) {
        setEvents((prev) => prev.filter((evt) => evt.id !== eventId));
        toast({
          title: "Event deleted",
          status: "info",
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Error",
          description: "Event not found.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error: any) {
      console.error("Error deleting event:", error.message);
      toast({
        title: "Error",
        description: error.message || "Error deleting event.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      // Espera un momento antes de permitir otra eliminación
      setTimeout(() => {
        setIsDeleting(false);
      }, 1000);
    }
  };

  const handleSubmitEvent = async (eventData: Event) => {
    try {
      if (editingEvent) {
        const res = await axiosInstance.put(`/events/${eventData.id}`, {
          name: eventData.name,
          location: eventData.location,
          startDate: new Date(eventData.startDate).toISOString().split("T")[0],
          endDate: new Date(eventData.endDate).toISOString().split("T")[0],
          ageGroup: eventData.ageGroup,
          image: eventData.image,
        });
        if (res.data.success) {
          const updatedEvent = {
            id: res.data.event.ID,
            name: res.data.event.NAME,
            location: res.data.event.LOCATION,
            startDate: new Date(res.data.event.START_DATE),
            endDate: new Date(res.data.event.END_DATE),
            ageGroup: res.data.event.AGE_GROUP,
            image: res.data.event.IMAGE,
          };
          setEvents((prev) =>
            prev.map((evt) => (evt.id === eventData.id ? updatedEvent : evt))
          );
          toast({
            title: "Event updated",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        }
      } else {
        const res = await axiosInstance.post("/events", {
          name: eventData.name,
          location: eventData.location,
          startDate: new Date(eventData.startDate).toISOString().split("T")[0],
          endDate: new Date(eventData.endDate).toISOString().split("T")[0],
          ageGroup: eventData.ageGroup,
          image: eventData.image,
        });
        if (res.data.success) {
          const newEvent = {
            id: res.data.event.ID,
            name: res.data.event.NAME,
            location: res.data.event.LOCATION,
            startDate: new Date(res.data.event.START_DATE),
            endDate: new Date(res.data.event.END_DATE),
            ageGroup: res.data.event.AGE_GROUP,
            image: res.data.event.IMAGE,
          };
          setEvents((prev) => [...prev, newEvent]);
          toast({
            title: "Event created",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        }
      }
      setIsModalOpen(false);
    } catch (error: any) {
      console.error("Error submitting event:", error.message);
      toast({
        title: "Error",
        description: error.message || "Error saving event.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  const filteredEvents = events.filter(
    (evt) =>
      (evt.name || "").toLowerCase().includes(searchQuery) ||
      (evt.location || "").toLowerCase().includes(searchQuery)
  );

  return (
    <Box width="100%" minHeight="100vh" bg="#F3F3F3">
      <Container maxW="1300px" py={10}>
        <Heading as="h1" size="xl" mb={6}>
          Events
        </Heading>

        <Flex mb={6} justifyContent="space-between" alignItems="center">
          <Input
            bg="white"
            placeholder="Search events"
            maxW="400px"
            value={searchQuery}
            onChange={handleSearch}
          />

          <Button
            leftIcon={<FiPlus />}
            colorScheme="blue"
            bg="#1B154B"
            color="white"
            _hover={{ bg: "#1B154B" }}
            onClick={handleAddEvent}
          >
            Add Event
          </Button>
        </Flex>

        {isLoading ? (
          <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
            {[1, 2, 3].map((n) => (
              <Skeleton key={n} height="300px" borderRadius="16px" />
            ))}
          </SimpleGrid>
        ) : (
          <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
            {filteredEvents.map((event) => {
              const today = new Date();
              let status = "Done";
              let badgeColor = "blue";
              if (today >= event.startDate && today <= event.endDate) {
                status = "Started";
                badgeColor = "green";
              } else if (today < event.startDate) {
                status = "Arrived";
                badgeColor = "purple";
              }
              // Construir la URL de la imagen:
              const imageStr = event.image ? event.image.trim() : "";
              const bgImage =
                imageStr.startsWith("data:")
                  ? imageStr
                  : `/images/event_fondos/${imageStr || "Event1.jpg"}`;
              console.log(`Event ${event.id} bgImage:`, bgImage);
              return (
                <Box
                  key={event.id}
                  position="relative"
                  borderRadius="16px"
                  overflow="hidden"
                  height="300px"
                  boxShadow="0px 0px 18.5px 1px rgba(0, 0, 0, 0.08)"
                  style={{ backgroundImage: `url("${bgImage}")` }}
                  backgroundSize="cover"
                  backgroundPosition="center"
                >
                  <Flex position="absolute" top={4} left={4}>
                    <Badge colorScheme={badgeColor} px={2} py={1} borderRadius="5px">
                      {status}
                    </Badge>
                  </Flex>
                  <Flex position="absolute" top={4} right={4} gap={2}>
                    <IconButton
                      aria-label="Edit Event"
                      icon={<FiEdit />}
                      size="sm"
                      onClick={() => handleEditEvent(event)}
                    />
                    <IconButton
                      aria-label="Delete Event"
                      icon={<FiTrash2 />}
                      size="sm"
                      onClick={() => handleDeleteEvent(event.id)}
                      isDisabled={isDeleting}
                    />
                  </Flex>
                  <Flex
                    position="absolute"
                    bottom={0}
                    width="100%"
                    bg="rgba(0,0,0,0.5)"
                    p={3}
                    justifyContent="center"
                    flexDir="column"
                    alignItems="center"
                  >
                    <Text color="white" fontWeight="bold" fontSize="lg">
                      {event.name}
                    </Text>
                    <Text color="white" fontSize="md">
                      {event.location}
                    </Text>
                    <Text color="white" fontSize="sm">
                      {`${event.startDate.toLocaleDateString()} - ${event.endDate.toLocaleDateString()}`}
                    </Text>
                  </Flex>
                </Box>
              );
            })}
          </SimpleGrid>
        )}

        <EventFormModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onSubmit={handleSubmitEvent}
          initialData={editingEvent || undefined}
        />
      </Container>
    </Box>
  );
};

export default EventPage;
