// src/services/stripeOneTimeApi.ts
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = `${process.env.REACT_APP_BASE_URL}/api/stripe`;

export const stripeOneTimeApi = createApi({
  reducerPath: "stripeOneTimeApi",
  baseQuery: fetchBaseQuery({ baseUrl }),
  endpoints: (builder) => ({
    createOneTimeCheckoutSession: builder.mutation({
      query: (data) => ({
        url: "create-one-time-checkout-session",
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: data,
      }),
    }),
  }),
});

export const { useCreateOneTimeCheckoutSessionMutation } = stripeOneTimeApi;
