import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import HomePageProtected from "./pages/HomePageProtected";
import ForgotPassword from "./pages/ForgotPasswordPage";
import SetANewPasswordPage from "./pages/SetANewPasswordPage";
import CreatePlayerWizard from "./pages/CreatePlayerWizard";
import PlayerRegistry from "./pages/PlayersRegistry";
import SearchPlayers from "./pages/SearchPlayers";
import MyRoster from "./pages/MyRoster";
import { UserList } from "./components/common/UserList";
import ScoutAccess from "./pages/ScoutAccess";
import Accounts from "./pages/Accounts";
import BallParks from "./pages/BallParks";
import WaifPlayerList from "./pages/WaifPlayerList";
import UpgradePlayerWizard from "./pages/UpgradePlayerWizard";
import AnalitycsSearchPage from "./pages/Analitycs";
import PlayerStatsPage from "./pages/PlayerStatsPage";
import Association from "./pages/Association";
import EventPage from "./pages/Event";
import PlayerFullStatsPage from "./pages/PlayerFullStatsPage";
import ShowcasePlayers from "./pages/ShocasePlayers";

const AppRoutes: React.FC = () => (
  <Router>
    <Routes>
      <Route path="/stats-home" element={<LoginPage />} />
      <Route path="/home" element={<HomePage />} />
      <Route path="/" element={<AnalitycsSearchPage />} />
      <Route
        path="/player-stats-results/:statsCode"
        element={<PlayerStatsPage />}
      />
      <Route path="/registration" element={<RegisterPage />} />
      <Route element={<HomePageProtected />}>
        <Route path="/authenticated" element={<>Authenticated</>} />
        <Route path="/news" element={<>News</>} />
        <Route path="/league" element={<>League</>} />
        <Route path="/team" element={<>Team</>} />
        <Route path="/notifications" element={<>Notifications</>} />
        <Route path="/association" element={<Association />} />
        <Route path="/event" element={<EventPage />} />
        <Route path="/accounts" element={<Accounts />} />
        <Route path="/scout-access" element={<ScoutAccess />} />
        <Route path="/settings" element={<>Settings</>} />
        <Route path="/players" element={<PlayerRegistry />} />
        <Route path="/search-players" element={<SearchPlayers />} />
        <Route path="/my-roster" element={<MyRoster />} />
        <Route path="/showcase-players" element={<ShowcasePlayers />} />
        <Route path="/player-stats/:statsCode" element={<PlayerFullStatsPage />} />
        <Route path="/create-player" element={<CreatePlayerWizard />} />
        <Route path="/ballparks" element={<BallParks />} />
        <Route path="/waif-players" element={<WaifPlayerList />} />
        <Route path="/create-player/*" element={<CreatePlayerWizard />} />
        <Route path="/upgrade-player/:id" element={<UpgradePlayerWizard />} />
      </Route>
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password" element={<SetANewPasswordPage />} />
      <Route path="*" element={<>Not found</>} />
    </Routes>
  </Router>
);

export default AppRoutes;
