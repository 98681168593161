// src/components/players_registry/PlayerCard.tsx
import React, { memo, useState, useEffect } from 'react';
import {
  Box,
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  VStack,
  useDisclosure,
  Skeleton,
  Spinner,
} from '@chakra-ui/react';
import {
  FaEllipsisH,
  FaTimes,
  FaEdit,
  FaShareAlt,
  FaRedoAlt,
  FaChartBar,
} from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import ModernCard from './ModernCard';
import ModernCardBack from './ModernCardBack';
import WaifModernCard from './WaifModernCard';
import WaifVintageCard from './WaifVintageCard';
import VintageCard from './VintageCard'; // Tarjeta frontal vintage
import VintageCardBack from './VintageCardBack';
import axiosInstance from '../../api/axiosConfig';

interface Player {
  LEAGUE: string | undefined;
  SELECTED_PLAN: any;
  FIRST_NAME: string;
  LAST_NAME: string;
  PLAYING_POSITIONS: string;
  DATE_OF_BIRTH: string;
  UNIFORM_NUMBER: string;
  STATS_PLAYER_CODE: string;
  STATUS: string;
  HEIGHT: string;
  WEIGHT: string;
  BATTING_HAND: string;
  THROWING_HAND: string;
  GRADUATION_YEAR: string;
}

interface PlayerCardProps {
  player: Player;
  isModern: boolean;
  onStatsClick?: () => void;
}

const PlayerCard: React.FC<PlayerCardProps> = ({ player, isModern, onStatsClick }) => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  // Para jugadores Waif se fuerza que no se pueda hacer flip.
  const [isFlipped, setIsFlipped] = useState(false);
  useEffect(() => {
    if (player.STATUS === 'Waif') {
      setIsFlipped(false);
    }
  }, [player.STATUS]);

  const handleFlip = () => setIsFlipped(!isFlipped);

  // Estados para estadísticas (se cargan solo para jugadores no-Waif y cuando la tarjeta está flippeada)
  const [last3Batting, setLast3Batting] = useState<any[]>([]);
  const [last3Pitching, setLast3Pitching] = useState<any[]>([]);
  const [last3Fielding, setLast3Fielding] = useState<any[]>([]);
  const [ranking, setRanking] = useState<any>(null);
  const [statsLoading, setStatsLoading] = useState(false);

  useEffect(() => {
    if (player.STATUS !== 'Waif' && isFlipped) {
      setStatsLoading(true);
      const fetchStats = async () => {
        try {
          const resBatting = await axiosInstance.get(
            `/players/by-id/${player.STATS_PLAYER_CODE}/batting/filtered`,
            { params: { limit: 3 } }
          );
          const resPitching = await axiosInstance.get(
            `/players/by-id/${player.STATS_PLAYER_CODE}/pitching/filtered`,
            { params: { limit: 3 } }
          );
          const resFielding = await axiosInstance.get(
            `/players/by-id/${player.STATS_PLAYER_CODE}/fielding/filtered`,
            { params: { limit: 3 } }
          );
          const resRanking = await axiosInstance.get(
            `/players/by-id/${player.STATS_PLAYER_CODE}/ranking`
          );
          setLast3Batting(resBatting.data.battingStats || []);
          setLast3Pitching(resPitching.data.pitchingStats || []);
          setLast3Fielding(resFielding.data.fieldingStats || []);
          setRanking(resRanking.data.ranking || null);
        } catch (error) {
          console.error("Error fetching stats for", player.STATS_PLAYER_CODE, error);
          setLast3Batting([]);
          setLast3Pitching([]);
          setLast3Fielding([]);
          setRanking(null);
        }
        setStatsLoading(false);
      };
      fetchStats();
    }
  }, [isFlipped, player.STATS_PLAYER_CODE, player.STATUS]);

  return (
    <Box
      bg="white"
      p={2}
      borderRadius="xl"
      boxShadow="lg"
      border="1px solid #D0D0E1"
      height="472px"
      width="100%"
      maxWidth="353px"

      minWidth="353px"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      position="relative"
    >
      {player.STATUS === 'Waif_delete' ? (
        // Para jugadores Waif, se muestra siempre la tarjeta frontal.
        isModern ? (
          <WaifModernCard
            playerName={`${player.FIRST_NAME} ${player.LAST_NAME}`}
            playerPositions={player.PLAYING_POSITIONS}
            statsPlayerCode={player.STATS_PLAYER_CODE}
          />
        ) : (
          <WaifVintageCard
            playerName={`${player.FIRST_NAME} ${player.LAST_NAME}`}
            playerPositions={player.PLAYING_POSITIONS}
            statsPlayerCode={player.STATS_PLAYER_CODE}
            uniformNumber={player.UNIFORM_NUMBER}
          />
        )
      ) : (
        // Para jugadores no-Waif:
        isModern ? (
          isFlipped ? (
            statsLoading ? (
              <Box
                height="472px"
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Spinner size="xl" color="#09264a" />
              </Box>
            ) : (
              <ModernCardBack
                playerName={`${player.FIRST_NAME} ${player.LAST_NAME}`}
                height={player.HEIGHT}
                weight={player.WEIGHT}
                bats={player.BATTING_HAND}
                throws={player.THROWING_HAND}
                dateOfBirth={player.DATE_OF_BIRTH}
                graduationYear={player.GRADUATION_YEAR}
                playingPositions={player.PLAYING_POSITIONS}
                leagueValidated={player.LEAGUE}
                selectedPlan={player.SELECTED_PLAN}
                last3Batting={last3Batting}
                last3Pitching={last3Pitching}
                last3Fielding={last3Fielding}
                ranking={ranking}
              />
            )
          ) : (
            <ModernCard
              playerName={`${player.FIRST_NAME} ${player.LAST_NAME}`}
              playerPositions={player.PLAYING_POSITIONS}
              statsPlayerCode={player.STATS_PLAYER_CODE}
            />
          )
        ) : (
          // Modo vintage:
          isFlipped ? (
            statsLoading ? (
              <Box
                height="472px"
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Spinner size="xl" color="#a12818" />
              </Box>            
            ) : (
              <VintageCardBack
                playerName={`${player.FIRST_NAME} ${player.LAST_NAME}`}
                height={player.HEIGHT}
                weight={player.WEIGHT}
                bats={player.BATTING_HAND}
                throws={player.THROWING_HAND}
                dateOfBirth={player.DATE_OF_BIRTH}
                graduationYear={player.GRADUATION_YEAR}
                playingPositions={player.PLAYING_POSITIONS}
                leagueValidated={player.LEAGUE}
                selectedPlan={player.SELECTED_PLAN}
                last3Batting={last3Batting}
                last3Pitching={last3Pitching}
                last3Fielding={last3Fielding}
                ranking={ranking}
              />
            )
          ) : (
            <VintageCard
              playerName={`${player.FIRST_NAME} ${player.LAST_NAME}`}
              playerPositions={player.PLAYING_POSITIONS}
              statsPlayerCode={player.STATS_PLAYER_CODE}
              uniformNumber={player.UNIFORM_NUMBER}
            />
          )
        )
      )}

      <Popover isOpen={isOpen} onClose={onClose} placement="top-end">
        <PopoverTrigger>
          <IconButton
            icon={isOpen ? <FaTimes /> : <FaEllipsisH />}
            aria-label="Options"
            borderRadius="full"
            color="white"
            backgroundColor="#1B154B"
            position="absolute"
            bottom="15px"
            right="16px"
            _hover={{ backgroundColor: 'blue.800' }}
            w="42px"
            h="42px"
            fontSize="20px"
            zIndex={60}
            onClick={isOpen ? onClose : onOpen}
          />
        </PopoverTrigger>
        <PopoverContent width="auto" bg="transparent" boxShadow="none" _focus={{ boxShadow: 'none' }}>
          <VStack spacing={2} alignItems="center" position="absolute" bottom="3px" right="1px">
            {player.STATUS === 'Waif' ? (
              <IconButton
                icon={<FaEdit />}
                aria-label="Edit Player"
                borderRadius="full"
                color="#1B154B"
                backgroundColor="white"
                border="1px solid #1B154B"
                w="36px"
                h="36px"
                fontSize="15px"
                _hover={{ backgroundColor: '#E2E8F0' }}
                onClick={() =>
                  navigate(`/upgrade-player/${player.STATS_PLAYER_CODE}`, { state: { player } })
                }
              />
            ) : (
              <>
                <IconButton
                  icon={<FaShareAlt />}
                  aria-label="Share"
                  borderRadius="full"
                  color="#1B154B"
                  backgroundColor="white"
                  border="1px solid #1B154B"
                  w="36px"
                  h="36px"
                  fontSize="15px"
                  _hover={{ backgroundColor: '#E2E8F0' }}
                />
                <IconButton
                  icon={<FaRedoAlt />}
                  aria-label="Rotate Card"
                  borderRadius="full"
                  color="#1B154B"
                  backgroundColor="white"
                  border="1px solid #1B154B"
                  w="36px"
                  h="36px"
                  fontSize="15px"
                  _hover={{ backgroundColor: '#E2E8F0' }}
                  onClick={() => {
                    handleFlip();
                    onClose();
                  }}
                />
                <IconButton
                  icon={<FaChartBar />}
                  aria-label="Statistics"
                  borderRadius="full"
                  color="#1B154B"
                  backgroundColor="white"
                  border="1px solid #1B154B"
                  w="36px"
                  h="36px"
                  fontSize="15px"
                  _hover={{ backgroundColor: '#E2E8F0' }}
                  onClick={onStatsClick}
                />
              </>
            )}
          </VStack>
        </PopoverContent>
      </Popover>
    </Box>
  );
};

export default memo(PlayerCard);
